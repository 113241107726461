import React, { useContext, useEffect, useState } from 'react'
import { getWithdrawalTransactionAPI, withdrawalRequestAPI, getProfileAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import moment from 'moment'
import MainContext from '../../Configs/Context/MainContext'
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Button, InputGroup, FormGroup } from 'react-bootstrap'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Pagination from '../../Configs/Pagination/Pagination'

const IMG_PREFIX = process.env.REACT_APP_SERVER_URL;

const WithdrawalPage = () => {

  const { register, handleSubmit, formState: { errors }, setValue, watch, control } = useForm({});
  const [activeButton, setActiveButton] = useState('request');
  const controllers = []
  const [loading, setLoading] = useState(false);
  const [withdrawalRequestLoading, setWithdrawalRequestLoading] = useState(false);
  const [withdrawalTransactionsData, setWithdrawalTransactionsData] = useState([]);
  const context = useContext(MainContext)
  const [profileDetails, setProfileDetails] = useState('');

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(formatDate(getStartDate()));
  const [endDate, setEndDate] = useState(formatDate(getEndDate()));
  const [dateRange, setDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);
  const [prevSearch, setPrevSearch] = useState();

  const [totalRecord, setTotalRecord] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState('');

  const handleDateChange = (e) => {
    setStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDateRange([e[0], e[1]])
  }

  const getWithdrawalTransaction = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getWithdrawalTransactionAPI(startDate, endDate, currentPage, limit, controller)
    if (response.status === 200) {
      // console.log("response", response)
      setWithdrawalTransactionsData(response.data?.data)
      setTotalRecord(response.data.totalRecords);
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }


  useEffect(() => {
    if (startDate && endDate) {
      getWithdrawalTransaction()
    }
  }, [startDate, endDate, currentPage, limit])

  const handleSearch = (e) => {
    e.preventDefault();
    getWithdrawalTransaction()
    setCurrentPage(1);
    setPrevSearch({ startDate, endDate });
  }

  const handleResetFilter = () => {
    setStartDate(formatDate(getStartDate()))
    setEndDate(formatDate(getEndDate()))
    setDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    getWithdrawalTransaction()
  }

  const onSubmit = async (data) => {
    setWithdrawalRequestLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      amount: data.amount
    }
    const response = await withdrawalRequestAPI(body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      getWithdrawalTransaction()
      setWithdrawalRequestLoading(false)
      setValue('amount', '')
      getProfile()
    } else {
      toast.error(response.response.data.message);
      setWithdrawalRequestLoading(false)
    }
  }

  const getProfile = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getProfileAPI(controller)
    if (response.status === 200) {
      // console.log("response", response)
      setProfileDetails(response.data.data)
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  const handleInput = (e) => {
    const value = e.target.value;
    // Prevent 0 or negative numbers
    if (value === "0" || value.startsWith("-")) {
      e.target.value = ""; // Clear the invalid input
    }
  };

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('URL copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  return (
    <>
      <div className="page-wrapper p-3 back-page">
        <div className="page-content">
          <div className="row ">
            <div className="col-12 col-lg-9 col-xl-9">
              <div className="card w-100">
                <div className="card-body">
                  <div className='d-flex gap-3 withdrawal-btn-group'>
                    <button className={`${activeButton === 'request' ? 'withdrawal-active-btn' : ''}`}
                      onClick={() => setActiveButton('request')}>
                      Withdrawal Request
                    </button>
                    <button className={`${activeButton === 'history' ? 'withdrawal-active-btn' : ''}`}
                      onClick={() => setActiveButton('history')}>
                      Withdrawal History
                    </button>
                  </div>
                  <div className="mt-4">
                    {
                      activeButton === 'request' &&
                      <div className="">
                        <div className="row text-white">
                          <div className="col-md-6 d-flex flex-column justify-content-start">
                            {/* <div className="row">
                              <div className="col-md-5">
                                <label>Number of Eden Pass</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-control eden-search" type="text" placeholder="Number of Eden Battle Pass" onChange={(e) => setEden(e.target.value)} />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-5">
                                <label>Redeemable No</label>
                              </div>
                              <div className="col-md-7">
                                <input className="form-control quantity-search" type="text" placeholder="Enter quantity..." aria-label=".form-control-lg example" onChange={(e) => setQuantity(e.target.value)} />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12">
                                <button className="withdrawal-active-btn w-100" onClick={InitiateWithdrawal}>
                                  Withdrawal
                                </button>
                              </div>
                            </div> */}
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              <Form.Label>Enter Amount : </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  className="form-control eden-search"
                                  type="number"
                                  {...register('amount', {
                                    required: 'Amount is required',
                                    min: {
                                      value: context?.userSetting?.minWithdrawableAmount,
                                      message: `Value must be greater than or equal to ${context?.userSetting?.minWithdrawableAmount}`
                                    },
                                    max: {
                                      value: context?.userSetting?.maxWithdrawableAmount,
                                      message: `Value must be less than or equal to ${context?.userSetting?.maxWithdrawableAmount}`
                                    }
                                  })}
                                  placeholder="Enter Amount"
                                  onInput={(e) => {
                                    const value = e.target.value;
                                    if (value.includes('.') && value.split('.')[1].length > 5) {
                                      e.target.value = value.slice(0, value.indexOf('.') + 6);
                                    }
                                  }}
                                  step="0.00001"
                                />
                              </InputGroup>
                              {errors.amount && <span className="Err-msg">{errors.amount.message}</span>}
                              <p className='mt-2'>
                                Note :
                                <ul>
                                  {/* <li>Your withdrawal amount should be between {context?.userSetting?.minWithdrawableAmount} Fronx Token to {context?.userSetting?.maxWithdrawableAmount} Fronx Token.</li> */}
                                  <li>Min. Withdraw Amount: {context?.userSetting?.minWithdrawableAmount} Fronx</li>
                                </ul>
                              </p>
                              <p className='mt-3'>- Your withdrawal may take upto 48 hours to withdraw</p>
                              <p>- Withdraw charge : 1 Fronx</p>
                              <Row>
                                <Col md={12}>
                                  <Button type='submit' className='withdrawal-active-btn w-100' disabled={withdrawalRequestLoading}>
                                    {
                                      withdrawalRequestLoading ?
                                        <div className="spinner-border text-primary" role="status">
                                          <span className="sr-only">Loading...</span>
                                        </div>
                                        : "Withdrawal"
                                    }
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                          <div className="col-md-6">
                            <div className="box-l h-100">
                              <div className="mb-4"><h3>Wallet Amount :</h3></div>
                              <div><h1 style={{ fontFamily: 'cursive' }}>{profileDetails?.wallet}</h1></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {
                      activeButton === 'history' &&
                      <>
                        <div>
                          <form className='searchMain gap-2 mb-3' onSubmit={handleSearch}>
                            <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                              <div className='dashDateSearch'>
                                <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                                <FormGroup>
                                  <DateRangePicker
                                    className='dateSearch'
                                    onChange={handleDateChange}
                                    value={dateRange}
                                    showLeadingZeros
                                    format="dd/MM/y"
                                  />
                                </FormGroup>
                              </div>
                              {/* <Button type='submit' variant="secondary">
                                  <i class="fa-solid fa-magnifying-glass"></i>
                                    </Button> */}
                              <Button variant="secondary" onClick={handleResetFilter}>
                                <i class="fa-solid fa-retweet"></i>
                              </Button>
                            </div>
                          </form>
                        </div>
                        <div className="table-responsive">
                          <table className="table mb-0 table-modern table-hover mb-0 transaction-table">
                            <thead style={{ backgroundColor: '#0B1830' }}>
                              <tr>
                                <th scope="col" className='text-white'>No.</th>
                                <th scope="col" className='text-white'>Amount</th>
                                <th scope="col" className='text-white'>Status</th>
                                <th scope="col" className='text-white'>Request Date</th>
                                <th scope="col" className='text-white text-end'>Approved/Rejected Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                !loading && withdrawalTransactionsData?.length > 0 ? (
                                  withdrawalTransactionsData.map((item, index) => {
                                    let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                                    let approvedDate = item.approvedOrRejectedAt ? moment(item.approvedOrRejectedAt).format('DD/MM/YYYY') : '-'
                                    let status;
                                    if (item.status === 0) {
                                      status = 'Pending'
                                    } else if (item.status === 1) {
                                      status = 'Complete'
                                    } else if (item.status === 2) {
                                      status = "Reject"
                                    }
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td className='text-white'>{index + 1}</td>
                                          <td className='text-white'>{item.amount}</td>
                                          <td className={`text-white text-capitalize appointment ${status}`}>{status}</td>
                                          <td className='text-white'>{formattedTime}</td>
                                          <td className='text-white text-end'>{approvedDate === null || undefined ? '-' : approvedDate}</td>
                                        </tr>
                                      </>
                                    )
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={12} className="text-center text-white">
                                      {loading ? <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div> : "No data found"}
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>
                          <div className='centerListPagination'>
                            {!loading && totalPage > 1 &&
                              <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            }
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xxl-3 col-lg-3'>
              <div className="boderm" style={{ color: '#fff' }}>
                <h5>Deposit QR Code</h5>
                <div className="card radius-10 overflow-hidden w-100 mb-4 d-flex justify-content-center">

                  <div className="card-body border-m">
                    <div className="p-4 d-flex justify-content-center">
                      <img src={IMG_PREFIX + context?.userSetting?.qrImage} className="img-fluid" height={'100%'} width={'100%'} />
                    </div>
                    <div className='text-white text-center'>
                      <div className='text-wrap'>{context?.userSetting?.walletAddress} <i className="fa-regular fa-copy ms-2 text-danger" style={{ cursor: 'pointer' }} onClick={() => handleCopy(`${context?.userSetting?.walletAddress}`)}></i>
                      </div>
                    </div>
                  </div>

                </div>
                <ul style={{ marginBottom: '30px' }}>
                  <li> Deposit only Fronx token here.</li>
                  <li>Minimum deposit value : {Number(context?.userSetting?.minDepositAmount).toFixed(0)} Fronx</li>
                  <li>Your deposit reflect in 24 hours. If not please contact us.</li>
                  <li>Deposit or withdrawal will be done only from the wallet address provided by you.</li>
                </ul>

                {/* <div style={{ marginBottom: '50px', cursor: 'pointer' }} onClick={() => handleCopyURL(`${context?.userSetting?.youtubeLink}`)} className='gallery-card'>
                  <div className='gallery-content'>
                    <a href={`${context?.userSetting?.youtubeLink}`} target='_blank'>
                      <img src={IMG_PREFIX + context?.userSetting?.youtubeLinkThumbnail} height={'100%'} width={'100%'} />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WithdrawalPage
