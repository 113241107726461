import React, { useState, useContext, useEffect } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UpdateWalletAddressStatusChangeAPI } from '../../Configs/Utils/Apis/UserApis'

const WalletAddressStatusChangeModal = ({ setWalletAddressStatusChangeModal, id, getOneUser, getUserWiseWalletAddress }) => {

  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({});
  const [loading, setLoading] = useState(false)
  const controllers = [];

  const onSubmit = async (data) => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      status: Number(data.status)
    }
    const response = await UpdateWalletAddressStatusChangeAPI(id, body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      setWalletAddressStatusChangeModal(false)
      setValue('status', '')
      setLoading(false)
      getOneUser()
      getUserWiseWalletAddress()
    } else {
      toast.error(response.response.data.message);
      setLoading(false)
    }
  }

  return (
    <div>
      <Modal.Header closeButton>
        <h5>Wallet Address Status Change</h5>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-2">
                <Form.Label style={{ fontSize: "14px" }} className='mb-2'>Status: <span className='text-danger'>*</span></Form.Label>
                <InputGroup>
                  <Form.Select {...register('status', { required: true })}>
                    <option value=''>Select Status</option>
                    <option value='1'>Complete</option>
                    <option value='2'>Reject</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>

            <div>
              <Button onClick={() => { setWalletAddressStatusChangeModal(false) }} className='mt-3 me-2' disabled={loading} variant="secondary">
                Cancel
              </Button>
              <Button type='submit' variant="secondary" className='mt-3' disabled={loading}>
                {loading ?
                  <div className='text-center'>
                    <Spinner className="m-1" animation="border" role="status" style={{ width: '15px', height: '15px' }}>
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                  :
                  <>Submit <span className='ms-1'><i class="fa-solid fa-check"></i></span></>
                }
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Form>
    </div>
  )
}

export default WalletAddressStatusChangeModal
