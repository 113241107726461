const DASHBOARD = '/dashboard';
const FRONX_LOGIN = '/';
// const FRONX_LOGIN1 = '/'
const FRONX_SIGNUP = '/signup/';
// const FORGOT_PASSWORD_PAGE = '/forgot-password/'
const FRONX_verify_OTP = '/fronx-email-resend-otp/'
const DEPOSIT = '/deposit'
const WITHDRAWAL = '/withdrawal'
const REFERRAL = '/referral'
const PROFILE = '/profile'
const TERMS_CONDITION = '/terms-condition'
const PRIVACY_POLICY = '/privacy-policy'

const RESTORE_PASSWORD_PAGE = {
  LIST: "/fronx-reset-password",
  GET: "/fronx-reset-password/:id"
}

const FORGOT_PASSWORD_PAGE = {
  LIST: "/forgot-password/",
  GET: "/forgot-password/:id",
}

// Admin Route Start----------------------------//
const ADMIN_DASHBOARD = '/admin-dashboard';
const USER_LIST = {
  LIST: "/user-list/",
  GET: "/user-list/:id",
}
const ADMIN_DEPOSIT = '/admin-deposit';
const ADMIN_WITHDRAWAL = '/admin-withdrawal';
const ADMIN_PROFILE = '/admin-profile';
const ADMIN_SETTING = '/admin-setting';
const ADMIN_STACKING = '/admin-stacking';
// Admin Route End-------------------------------//

const USER_REFERRAL = {
  LIST: "/user-referral",
  GET: "/user-referral/:id",
};

const ROUTES = {
  FRONX_LOGIN,
  FRONX_SIGNUP,
  FORGOT_PASSWORD_PAGE,
  FRONX_verify_OTP,
  DASHBOARD,
  DEPOSIT,
  WITHDRAWAL,
  REFERRAL,
  PROFILE,
  USER_REFERRAL,
  ADMIN_DASHBOARD,
  USER_LIST,
  ADMIN_DEPOSIT,
  ADMIN_WITHDRAWAL,
  ADMIN_PROFILE,
  ADMIN_SETTING,
  TERMS_CONDITION,
  PRIVACY_POLICY,
  ADMIN_STACKING,
  RESTORE_PASSWORD_PAGE
}

export default ROUTES;