import React, { useContext, useState } from 'react'
import fronx_logo from '../../Assets/Images/final_logo.jpeg'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import MainContext from '../../Configs/Context/MainContext'
import { Form, Row, Col, Button, InputGroup, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { RestorePasswordAPI } from '../../Configs/Utils/Apis/UserApis'
import ROUTES from '../../Configs/Routes'

const FronxResetPasswordPAge = () => {

  const navigate = useNavigate()
  const { id } = useParams()
  const controllers = []
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
  const [loading, setLoading] = useState(false);
  const context = useContext(MainContext)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      new_password: data.password,
      confirm_password: data.confirm_password,
    }

    const response = await RestorePasswordAPI(id, body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      localStorage.setItem('fronxUserToken', response.data.token)
      setValue('password', '')
      setValue('confirm_password', '')
      navigate(ROUTES.FRONX_LOGIN)
      setLoading(false)
    } else {
      toast.error(response.response.data.message);
      setLoading(false)
    }
  }

  return (
    <div id="main-wrapper" className="login-register">
      <div className="container-fluid px-0">
        <div className="row g-0 min-vh-100">
          <div className="col-md-6" style={{ backgroundColor: '#414346' }}>
            <div className="">
              <div className="min-vh-100 d-flex flex-column login-left-side">
                <div className="row g-0">
                  <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                    <div className="logo mt-5 mb-5 mb-md-0">
                      <img src={fronx_logo} alt="" className='logo-img' height={'140px'} width={'200px'} />
                    </div>
                  </div>
                </div>
                <div className="row g-0 my-auto login-text">
                  <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                    <h1 className="mb-4 welcome-text" style={{ color: '#fff' }}>Welcome back!</h1>
                    <p className="mb-5 more-text" style={{ color: '#fff', fontSize: '1.125rem' }}>We are glad to see you again! Get access to your Orders, Wishlist and Recommendations.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 d-flex">
            <div className="container my-auto py-5">
              <div className="row g-0" s>
                <div className="col-11 col-sm-10 col-lg-9 col-xl-8 mx-auto">
                  <h3 className="fw-bolder mb-4">Restore Your Password</h3>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md={12}>
                        <Form.Group className='mb-2'>
                          <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
                          <InputGroup>
                            <Form.Control type={passwordVisible ? 'text' : 'password'} placeholder='Enter Password'  {...register('password', {
                              required: "Password is required",
                              validate: (value) => {
                                const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W_]{8,}$/;
                                return (
                                  regex.test(value) ||
                                  "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character"
                                );
                              },
                            })} />

                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible' onClick={() => { setPasswordVisible(!passwordVisible) }} style={{ width: '46px' }}>
                              <i className={passwordVisible ? "fa fa-eye" : "fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>
                          <p className='Err-msg'>{errors.password ? <div>{`${errors?.password?.message}`}</div> : ""}</p>

                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className='mb-2'>
                          <Form.Label>Confirm Password<span className='text-danger'>*</span></Form.Label>
                          <InputGroup>
                            <Form.Control type={confirmPasswordVisible ? 'text' : 'password'} placeholder='Enter Password' {...register('confirm_password', { required: "Confirm Password is Required", validate: value => value.trim().length > 7 || "Confirm Password length greater than 8 characters", })} />

                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setConfirmPasswordVisible(!confirmPasswordVisible) }} style={{ width: '46px' }}>
                              <i className={confirmPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>
                          <p className='Err-msg'>{errors.confirm_password ? <div>{`${errors?.confirm_password?.message}`}</div> : ""}</p>

                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="d-grid my-4">
                      <Button type='submit' className='login-submit-btn mt-3' disabled={loading}>
                        {
                          loading ?
                            <div className='text-center'>
                              <Spinner className="m-1" animation="border" role="status" style={{ height: "20px", width: "20px" }}>
                              </Spinner>
                            </div>
                            :
                            <>Submit</>
                        }
                      </Button>
                    </div>

                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FronxResetPasswordPAge
