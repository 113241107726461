import React, { useContext, useEffect, useState } from 'react'
import fronx_logo from '../../Assets/Images/final_logo.jpeg'
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Button, InputGroup, Spinner, Container } from 'react-bootstrap'
import ROUTES from '../../Configs/Routes';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { SignUpAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify';
import MainContext from '../../Configs/Context/MainContext';
import PhoneInput from 'react-phone-input-2'

const FronxSignUp = () => {

  const { register, handleSubmit, formState: { errors }, setValue, watch, control } = useForm({});
  const navigate = useNavigate()
  const params = useParams()
  const controllers = []
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [phone, setPhone] = useState('');
  const context = useContext(MainContext)
  const [isChecked, setIsChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);

  useEffect(() => {
    if (window.location.href.includes(ROUTES.USER_REFERRAL.LIST) && params.id) {
      setValue('referal_code', params.id)
    }
  }, []);

  // console.log("watch", watch('referal_code'))

  const onSubmit = async (data) => {

    if (!isChecked) {
      toast.error("Please select terms of service");
      return;
    }

    setCheckboxError(false);

    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      name: data.user_name,
      email: data.email,
      phone: phone,
      password: data.password,
      confirm_password: data.confirm_password,
      walletAddress: data.wallet_address,
      // referralCode: data.referal_code ? data.referal_code : null
      ...(data.referal_code && { referralCode: data.referal_code })
    }

    console.log("body", body)

    const response = await SignUpAPI(body, controller)
    if (response.status === 200) {
      navigate(ROUTES.FRONX_verify_OTP);
      toast.success(response.data.message);
      setValue('user_name', '')
      setValue('email', '')
      setPhone('')
      setValue('wallet_address', '')
      setValue('password', '')
      setValue('confirm_password', '')
      setValue('referal_code', '')
      setLoading(false)
      context.setSignInData(body)
      context.setSignupOTP(response.data.data)
    } else {
      toast.error(response.response.data.message);
      setLoading(false)
    }
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Extract the last segment of the URL
    const lastSegment = inputValue.split("/").pop();

    // Update the input field with the last segment
    setValue("referal_code", lastSegment);
  };

  // const handleInputChange = (e) => {
  //   const inputValue = e.target.value;
  //   console.log("inputValue", inputValue)

  //   const segments = inputValue.split("/");
  //   console.log("segments", segments)

  //   const referralCode = segments[segments.length - 2];

  //   console.log("referralCode", referralCode)

  //   if (referralCode && referralCode !== "user-referral") {
  //     setValue("referal_code", referralCode); // Set the referral code
  //   } else {
  //     setValue("referal_code", ""); // Clear if invalid
  //   }
  // }

  // const handleInputChange = (e) => {
  //   let inputValue = e.target.value.trim();

  //   if (inputValue.endsWith("/")) {
  //     inputValue = inputValue.slice(0, -1);
  //   }

  //   const segments = inputValue.split("/");

  //   const referralCode = segments[segments.length - 1];

  //   if (referralCode && referralCode !== "user-referral") {
  //     setValue("referal_code", referralCode);
  //   } else {
  //     setValue("referal_code", "");
  //   }
  // }

  return (
    <div id="main-wrapper" className="login-register">
      <div className="container-fluid px-0">
        <div className="row g-0 min-vh-100">
          <div className="col-md-6 d-flex flex-column  justify-content-center" style={{ backgroundColor: '#414346' }}>
            <div className="">
              <div className=" d-flex flex-column login-left-side">
                <div className="row g-0">
                  <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                    <div className="logo mt-5 mb-5 mb-md-0 text-center dash-logo">
                      <img src={fronx_logo} alt="" className='logo-img' height={'140px'} width={'200px'} />
                    </div>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                    <h1 className="mb-4" style={{ color: '#fff' }}>Welcome back!</h1>
                    <p className="mb-5" style={{ color: '#fff', fontSize: '1.125rem' }}>We are glad to see you again! Get access to your Orders, Wishlist and Recommendations.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 d-flex">
            <div className="container my-auto py-5">
              <div className="row g-0">
                <div className="col-11 col-sm-10 col-lg-9 col-xl-8 mx-auto">
                  <h3 className="fw-bolder mb-4">Sign Up</h3>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Username<span className='text-danger'>*</span></Form.Label>
                          <InputGroup>
                            <Form.Control type='text'
                              {...register("user_name", {
                                required: "Username is required",
                                validate: (value) => {
                                  const regex = /^(?!^[0-9]*$)(?!^[!@#$%^&*(),.?":{}|<>]*$)[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]+$/;
                                  return regex.test(value.trim()) || "Invalid User Name";
                                },
                              })} placeholder='Enter Username' autoComplete='false' />
                          </InputGroup>
                          <p className='Err-msg'>{errors.user_name && <p>{errors.user_name.message}</p>}</p>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className='mb-2'>
                          <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                          <Form.Control placeholder='Email' {...register('email', {
                            required: "Email is Required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Please Enter Valid Email",
                            }
                          })} />
                          <p className='Err-msg'>{errors.email ? <div>{`${errors?.email?.message}`}</div> : ""}</p>
                        </Form.Group>
                      </Col>

                      <Col xl={6} className='mb-4'>
                        <Form.Group>
                          <Form.Label>Phone</Form.Label>
                          <PhoneInput
                            name="phoneNumber"
                            autoFocus={true}
                            id="filled-basic"
                            variant="filled"
                            placeholder='Phone number'
                            country={"in"}
                            value={phone}
                            onChange={setPhone}
                            style={{ width: '100%' }}
                          />
                        </Form.Group>
                      </Col>

                      <Col xl={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>BEP-20 Wallet Address<span className='text-danger'>*</span></Form.Label>
                          <InputGroup>
                            <Form.Control type='text'
                              {...register("wallet_address", { required: true })} placeholder='BEP-20 Wallet Address' autoComplete='false' />
                          </InputGroup>
                          <p className='Err-msg'>{errors.wallet_address && <p>BEP-20 Wallet Address is required</p>}</p>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className='mb-2'>
                          <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
                          <InputGroup>
                            <Form.Control type={passwordVisible ? 'text' : 'password'} placeholder='Enter Password'  {...register('password', {
                              required: "Password is required",
                              validate: (value) => {
                                const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W_]{8,}$/;
                                return (
                                  regex.test(value) ||
                                  "Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character"
                                );
                              },
                            })} />

                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible' onClick={() => { setPasswordVisible(!passwordVisible) }} style={{ width: '46px' }}>
                              <i className={passwordVisible ? "fa fa-eye" : "fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>
                          <p className='Err-msg'>{errors.password ? <div>{`${errors?.password?.message}`}</div> : ""}</p>

                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className='mb-2'>
                          <Form.Label>Confirm Password<span className='text-danger'>*</span></Form.Label>
                          <InputGroup>
                            <Form.Control type={confirmPasswordVisible ? 'text' : 'password'} placeholder='Enter Password' {...register('confirm_password', { required: "Confirm Password is Required", validate: value => value.trim().length > 7 || "Confirm Password length greater than 8 characters", })} />

                            <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setConfirmPasswordVisible(!confirmPasswordVisible) }} style={{ width: '46px' }}>
                              <i className={confirmPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"} />
                            </InputGroup.Text>
                          </InputGroup>
                          <p className='Err-msg'>{errors.confirm_password ? <div>{`${errors?.confirm_password?.message}`}</div> : ""}</p>

                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="mb-2">
                          <Form.Label>Referral Code</Form.Label>
                          <InputGroup>
                            <Form.Control type='text'
                              {...register("referal_code")} onChange={handleInputChange} placeholder='Referral Code' autoComplete='false' />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* <div className="row">
                      <div className="col-md-6">
                        <input type="checkbox" /> <span className="">I agree to Staking Rewards </span>
                      </div>
                      <div className="col-md-6">
                        <p className="text text-end small "> <a href="#">Terms of Service</a>  & <a href="#"> Privacy Policy</a></p>
                      </div>
                    </div> */}

                    <div className=''>
                      {/* <input type="checkbox" checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)} />
                      <span className="">I agree to Staking Rewards <Link to={ROUTES.TERMS_CONDITION}>Terms of Service</Link></span> */}
                      <input type="checkbox" checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)} /> <span className="">I agree to Staking Rewards <Link to={'https://www.fronx.io/terms-conditions'}>Terms of Service</Link>  & <Link to={'https://www.fronx.io/privacy-policy'}> Privacy Policy</Link></span>
                    </div>

                    <div className="d-grid my-4">
                      <Button type='submit' className='login-submit-btn mt-3' disabled={loading}>
                        {
                          loading ?
                            <div className='text-center'>
                              <Spinner className="m-1" animation="border" role="status" style={{ height: "20px", width: "20px" }}>
                              </Spinner>
                            </div>
                            :
                            <>Sign Up</>
                        }
                      </Button>
                    </div>
                  </Form>
                  <p className="text-center text-muted mb-0">Already have an account? <Link to={ROUTES.FRONX_LOGIN}>Log In</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FronxSignUp
