import axios from "axios";
import { ENV_CONSTANTS } from "../Constants";

// console.log("ENV_CONSTANTS", ENV_CONSTANTS)

export const apiHandlerWithoutToken = async (method, url, requestBody = {}, controller) => {
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`
    const data = {
      method,
      url: baseURL,
      data: requestBody,
      cancelToken: new axios.CancelToken((cancel) => {
        controller.signal.addEventListener('abort', () => {
          cancel('Request canceled by component unmount.');
        });
      })
    };
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  } catch (error) {
    return error;
  }
}

export const apiHandler = async (method, url, requestBody = {}, controller) => {
  const Token = localStorage.getItem('fronxUserToken');
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`;
    const data = {
      method,
      url: baseURL,
      headers: {
        'Content-type': 'application/json',
        Accept: 'Application/json',
        Authorization: (Token ? `Bearer ${Token}` : [])
      },
      data: requestBody,
      cancelToken: new axios.CancelToken((cancel) => {
        controller.signal.addEventListener('abort', () => {
          cancel('Request canceled by component unmount.')
        });
      })
    };
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  } catch (error) {
    return error
  }
}

export const apiHandlerWitFile = async (method, url, requestBody = {}, controller) => {
  const Token = localStorage.getItem('fronxUserToken');
  try {
    const baseURL = `${ENV_CONSTANTS.API_SERVER_URL}${url}`;
    const data = {
      method,
      url: baseURL,
      headers: {
        'Content-type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: (Token ? `Bearer ${Token}` : []),
      },
      data: requestBody,
      cancelToken: new axios.CancelToken((cancel) => {
        controller.signal.addEventListener('abort', () => {
          cancel('Request canceled by component unmount.');
        });
      })
    }
    return axios(data)
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
}