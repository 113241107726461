import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import fronx_logo from '../../Assets/Images/final_logo.jpeg'
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { ForgotPasswordAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify';
import MainContext from '../../Configs/Context/MainContext';
import ROUTES from '../../Configs/Routes';
import { Link } from 'react-router-dom';

const ForgotPasswordPage = () => {

  const controllers = []
  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({});
  const [loading, setLoading] = useState(false);
  const context = useContext(MainContext)

  const onSubmit = async (data) => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      email: watch('email'),
    }
    const response = await ForgotPasswordAPI(body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      setLoading(false)
    } else {
      toast.error(response.response.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    setValue('email', context.signInData)
  }, [context])

  return (
    <>
      <div id="main-wrapper" className="login-register">
        <div className="container-fluid px-0">
          <div className="row g-0 min-vh-100">
            <div className="col-md-6 d-flex flex-column  justify-content-center" style={{ backgroundColor: '#414346' }}>
              <div className="">
                <div className=" d-flex flex-column login-left-side">
                  <div className="row g-0">
                    <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                      <div className="logo mt-5 mb-5 mb-md-0 text-center dash-logo">
                        <img src={fronx_logo} alt="" className='logo-img' height={'140px'} width={'200px'} />
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                      <h1 className="mb-4" style={{ color: '#fff' }}>Welcome back!</h1>
                      <p className="mb-5" style={{ color: '#fff', fontSize: '1.125rem' }}>We are glad to see you again! Get access to your Orders, Wishlist and Recommendations.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="container my-auto py-5">
                <div className="row g-0">
                  <div className="col-11 col-sm-10 col-lg-9 col-xl-8 mx-auto">
                    <h3 className="fw-bolder mb-4">Forgot Password</h3>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={12}>
                          <Form.Group className='mb-2'>
                            <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                            <Form.Control placeholder='Enter Your Email Address' {...register('email', {
                              required: "Email is Required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please Enter Valid Email",
                              }
                            })} />
                            <p className='Err-msg'>{errors.email ? <div>{`${errors?.email?.message}`}</div> : ""}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-grid my-4">
                        <Button type='submit' className='login-submit-btn' disabled={loading}>
                          {
                            loading ?
                              <div className='text-center'>
                                <Spinner className="m-1" animation="border" role="status" style={{ height: "20px", width: "20px" }}>
                                </Spinner>
                              </div>
                              :
                              <>Submit</>
                          }
                        </Button>
                      </div>
                      <div className='text-end'>
                        <Link to={ROUTES.FRONX_LOGIN}>Log In</Link>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordPage