import React, { useContext } from 'react'
import fronx_logo from '../../Assets/Images/fronx_logo.png'
import MainContext from '../../Configs/Context/MainContext';
import { Link, useLocation } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';
import icon_1 from '../../Assets/Images/icon_1.png'
import icon_2 from '../../Assets/Images/icon_2.png'
import icon_3 from '../../Assets/Images/icon_3.png'
import active_icon_2 from '../../Assets/Images/active_icon_2.png'
import active_icon_1 from '../../Assets/Images/active_icon_1.png'

const AdminSideBar = () => {

  const context = useContext(MainContext);
  const location = useLocation();

  const user = context.isToggled;

  const handleCloseSideBar = () => {
    if (window.innerWidth <= 1024) {
      context.setIsToggled(!context.isToggled)
    }
  }

  return (
    <div className={`wrapper ${user ? 'toggled' : ''}`}>
      <div className="sidebar-wrapper" data-simplebar="true">
        <ul className="metismenu" id="menu">
          <Link to={ROUTES.ADMIN_DASHBOARD} className="logo">
            <img src={fronx_logo} className="logo-icon" />
          </Link>
          <li>
            <Link to={ROUTES.ADMIN_DASHBOARD} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.ADMIN_DASHBOARD ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i className="fa-solid fa-border-all"></i> */}
                <img src={`${location.pathname === ROUTES.ADMIN_DASHBOARD ? `${active_icon_2}` : `${icon_2}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title boss">
                Dashboard
              </div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.USER_LIST.LIST} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.USER_LIST.LIST ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i className="fa-solid fa-border-all"></i> */}
                <img src={`${location.pathname === ROUTES.USER_LIST.LIST ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title boss">
                User
              </div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.ADMIN_DEPOSIT} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.ADMIN_DEPOSIT ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i class="fa-solid fa-money-bill"></i> */}
                <img src={`${location.pathname === ROUTES.ADMIN_DEPOSIT ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Deposit</div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.ADMIN_WITHDRAWAL} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.ADMIN_WITHDRAWAL ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                <img src={`${location.pathname === ROUTES.ADMIN_WITHDRAWAL ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Withdrawal</div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.ADMIN_SETTING} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.ADMIN_SETTING ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                <img src={`${location.pathname === ROUTES.ADMIN_SETTING ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Setting</div>
            </Link>
          </li>
          {/* <li>
            <Link to={ROUTES.REFERRAL} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.REFERRAL ? 'active' : ''}`}>
              <div className="d-flex align-items-center">
                <img src={`${location.pathname === ROUTES.REFERRAL ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Referral</div>
            </Link>
          </li> */}
          <li>
            <Link to={'https://www.fronx.io/'} className={`text-decoration-none ${location.pathname === ROUTES.PROFILE ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i class="fa-solid fa-money-bill"></i> */}
                <img src={icon_3} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Profile</div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="overlay toggle-icon" onClick={handleCloseSideBar}></div>
    </div>
  )
}

export default AdminSideBar
