import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='d-flex justify-content-center container mt-5' style={{ padding: '20px 0px' }}>
      <h5>Welcome to Fronx! These Privacy & Policy govern your use of our website,
        services, and applications (collectively, the "Services"). By accessing or using our
        Services, you agree to these Terms. If you do not agree, please do not use our Services.</h5>
    </div>
  )
}

export default PrivacyPolicy
