import React, { useContext, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import SideBar from './SideBar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import ROUTES from '../Configs/Routes'
import MainContext from '../Configs/Context/MainContext'
import AdminSideBar from '../Components/Admin/AdminSideBar'

const UserLayout = () => {

  const navigate = useNavigate()
  const userToken = localStorage.getItem('fronxUserToken')
  const context = useContext(MainContext)
  const location = useLocation()

  useEffect(() => {
    if (!userToken) {
      navigate(ROUTES.FRONX_LOGIN);
    }
  }, [navigate, userToken])

  useEffect(() => {
    if (context?.profile?.role === "admin" && location.pathname === ROUTES.DASHBOARD) {
      navigate(ROUTES.USER_LIST.LIST)
    }
  }, [context?.profile?.role])

  return (
    <div>
      {
        context?.profile?.role === "admin" ?
          <div>
            <AdminSideBar />
          </div> :
          <div>
            <SideBar />
          </div>
      }
      <div className='LayoutDiv'>
        <div className='headerDiv'>
          <Header />
        </div>
        <div className='outletDiv' style={{ backgroundColor: '#070d0e' }}>
          <Outlet />
        </div>
        <div className='footerDiv'>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default UserLayout