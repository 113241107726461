import UserLayout from "./Components/UserLayout";
import ROUTES from "./Configs/Routes";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FronxLogin from "./Components/Fronx/FronxLogin";
import ContextState from "./Configs/Context/ContextState";
import FronxSignUp from "./Components/Fronx/FronxSignUp";
import { ToastContainer } from 'react-toastify';
import ForgotPasswordPage from "./Components/Fronx/ForgotPasswordPage";
import VerifyOTP from "./Components/Fronx/VerifyOTP";
import DashboardPage from "./Components/User/DashboardPage";
import DepositPage from "./Components/User/DepositPage";
import ReferralPage from "./Components/User/ReferralPage";
import WithdrawalPage from "./Components/User/WithdrawalPage";
import ProfilePage from "./Components/User/ProfilePage";
import UserList from "./Components/Admin/UserList";
import ViewUser from "./Components/Admin/ViewUser";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import AdminDeposit from "./Components/Admin/AdminDeposit";
import AdminWithdrawal from "./Components/Admin/AdminWithdrawal";
import Setting from "./Components/Admin/Setting";
import TermsCondition from "./Components/TermsCondition";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import StackingPage from "./Components/Admin/StackingPage";
import FronxResetPasswordPAge from "./Components/Fronx/FronxResetPasswordPAge";

function App() {
  return (
    <Router>
      <ContextState>
        <Routes>
          <Route element={<UserLayout />}>
            <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
            <Route path={ROUTES.DEPOSIT} element={<DepositPage />} />
            <Route path={ROUTES.WITHDRAWAL} element={<WithdrawalPage />} />
            <Route path={ROUTES.REFERRAL} element={<ReferralPage />} />
            <Route path={ROUTES.PROFILE} element={<ProfilePage />} />

            {/* Admin */}
            <Route path={ROUTES.USER_LIST.LIST} element={<UserList />} />
            <Route path={ROUTES.USER_LIST.GET} element={<ViewUser />} />
            <Route path={ROUTES.ADMIN_DASHBOARD} element={<AdminDashboard />} />
            <Route path={ROUTES.ADMIN_DEPOSIT} element={<AdminDeposit />} />
            <Route path={ROUTES.ADMIN_WITHDRAWAL} element={<AdminWithdrawal />} />
            <Route path={ROUTES.ADMIN_SETTING} element={<Setting />} />
            <Route path={ROUTES.ADMIN_STACKING} element={<StackingPage />} />
            {/* Admin */}
          </Route>
          <Route path={ROUTES.FRONX_LOGIN} element={<FronxLogin />} />
          {/* <Route path={ROUTES.FRONX_LOGIN1} element={<FronxLogin />} /> */}
          <Route path={ROUTES.FRONX_SIGNUP} element={<FronxSignUp />} />
          <Route path={ROUTES.USER_REFERRAL.GET} element={<FronxSignUp />} />
          <Route path={ROUTES.FORGOT_PASSWORD_PAGE.LIST} element={<ForgotPasswordPage />} />
          <Route path={ROUTES.FRONX_verify_OTP} element={<VerifyOTP />} />
          <Route path={ROUTES.TERMS_CONDITION} element={<TermsCondition />} />
          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.FORGOT_PASSWORD_PAGE.GET} element={<FronxResetPasswordPAge />} />
        </Routes>
      </ContextState>

      <ToastContainer />
    </Router>
  );
}

export default App;