import React, { useContext } from 'react'
import fronx_logo from '../Assets/Images/final_logo.jpeg'
import MainContext from '../Configs/Context/MainContext';
import { Link, useLocation } from 'react-router-dom';
import ROUTES from '../Configs/Routes';
import icon_1 from '../Assets/Images/icon_1.png'
import icon_2 from '../Assets/Images/icon_2.png'
import icon_3 from '../Assets/Images/icon_3.png'
import active_icon_2 from '../Assets/Images/active_icon_2.png'
import active_icon_1 from '../Assets/Images/active_icon_1.png'

const SideBar = () => {

  const context = useContext(MainContext);
  const location = useLocation();

  const user = context.isToggled;

  const handleCloseSideBar = () => {
    if (window.innerWidth <= 1024) {
      context.setIsToggled(!context.isToggled)
    }
  }

  return (
    <div className={`wrapper ${user ? 'toggled' : ''}`}>
      <div className="sidebar-wrapper" data-simplebar="true">
        <ul className="metismenu" id="menu">
          
          <Link to={ROUTES.DASHBOARD} className="logo">
            <img src={fronx_logo} className="logo-icon" />
          </Link>
          <li>
            <Link to={ROUTES.DASHBOARD} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.DASHBOARD ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i className="fa-solid fa-border-all"></i> */}
                <img src={`${location.pathname === ROUTES.DASHBOARD ? `${active_icon_2}` : `${icon_2}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title boss">
                Dashboard
              </div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.DEPOSIT} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.DEPOSIT ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i class="fa-solid fa-money-bill"></i> */}
                <img src={`${location.pathname === ROUTES.DEPOSIT ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Deposit</div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.WITHDRAWAL} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.WITHDRAWAL ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i class="fa-solid fa-money-bill"></i> */}
                <img src={`${location.pathname === ROUTES.WITHDRAWAL ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Withdrawal</div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.REFERRAL} href="#!" className={`text-decoration-none ${location.pathname === ROUTES.REFERRAL ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i class="fa-solid fa-money-bill"></i> */}
                <img src={`${location.pathname === ROUTES.REFERRAL ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Referral</div>
            </Link>
          </li>
          <li>
            <Link to={ROUTES.ADMIN_STACKING} className={`text-decoration-none ${location.pathname === ROUTES.ADMIN_STACKING ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                <img src={`${location.pathname === ROUTES.ADMIN_STACKING ? `${active_icon_1}` : `${icon_1}`}`} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Staking Reward</div>
            </Link>
          </li>
          <li>
            <Link to={'https://www.fronx.io/'} className={`text-decoration-none`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i class="fa-solid fa-money-bill"></i> */}
                <img src={icon_3} height={'65%'} width={'65%'} />
              </div>
              <div className="menu-title">Home</div>
            </Link>
          </li>
          <li>
            <Link to={'https://www.fronx.io/contactus'} className={`text-decoration-none ${location.pathname.includes('contactus') ? 'active' : ''}`} onClick={handleCloseSideBar}>
              <div className="d-flex align-items-center">
                {/* <i class="fa-solid fa-money-bill"></i> */}
                {/* <img src={icon_3} height={'65%'} width={'65%'} /> */}
                <i className='fa-sharp-duotone fa-regular fa-address-book fa-fw' />
              </div>
              <div className="menu-title" style={{ marginLeft: '14px' }}>Contact Us</div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="overlay toggle-icon" onClick={handleCloseSideBar}></div>
    </div>
  )
}

export default SideBar