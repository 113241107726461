import React, { useContext, useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AddAmountAPI } from '../../Configs/Utils/Apis/UserApis'
import MainContext from '../../Configs/Context/MainContext';

const AddAmountModal = ({ setAddAmountModal, id, getDepositUserWise, getAdminStackingReward, AdminReferralDetails, getOneUser }) => {

  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({});
  const [loading, setLoading] = useState(false)
  const controllers = [];
  const context = useContext(MainContext)
  const validateNoWhiteSpace = (value) => {
    return value?.trim() !== "";
  };

  const onSubmit = async (data) => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      amount: data.amount,
      depositDate: data.date
    }
    const response = await AddAmountAPI(id, body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      setAddAmountModal(false)
      setValue('amount', '')
      setValue('date', '')
      setLoading(false)
      getDepositUserWise()
      getAdminStackingReward()
      AdminReferralDetails()
      getOneUser()
    } else {
      toast.error(response.response.data.message);
      setLoading(false)
    }
  }

  return (
    <div>
      <Modal.Header closeButton>
        <h5>Add Amount</h5>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-2">
                <Form.Label style={{ fontSize: "14px" }} className="mb-2">
                  Amount: <span className="text-danger">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    {...register("amount", {
                      required: "Amount is required",
                      validate: (value) => {
                        const valueAsString = value.toString();

                        // Check for maximum 5 decimal places
                        if (!/^\d+(\.\d{0,5})?$/.test(valueAsString)) {
                          return "Value must have at most 5 decimal places";
                        }

                        // Ensure value is not below the minDepositAmount
                        const minDepositAmount = parseFloat(parseFloat(context?.userSetting?.minDepositAmount).toFixed(0));
                        if (minDepositAmount && value < minDepositAmount) {
                          return `Value must be greater than or equal to ${minDepositAmount}`;
                        }

                        return true;
                      },
                    })}
                    onInput={(e) => {
                      const value = e.target.value;
                      // Restricting the number of decimal places to 5
                      if (value.includes('.') && value.split('.')[1].length > 5) {
                        e.target.value = value.slice(0, value.indexOf('.') + 6);
                      }
                    }}
                    step="any"
                  />
                </InputGroup>
                <p className="Err-msg">
                  {errors.amount && <span>{errors.amount.message}</span>}
                </p>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="mb-2">
                <Form.Label style={{ fontSize: "14px" }} className='mb-2'>Date: <span className='text-danger'>*</span></Form.Label>
                <InputGroup>
                  <Form.Control type='date' placeholder='date' {...register("date", { required: true, validate: validateNoWhiteSpace })} />
                </InputGroup>
                <p className='Err-msg'>{errors.date && <p>Date is Required</p>}</p>
              </Form.Group>
            </Col>

            <div>
              <Button onClick={() => { setAddAmountModal(false) }} className='mt-3 me-2' disabled={loading} variant="secondary">
                Cancel
              </Button>
              <Button type='submit' variant="secondary" className='mt-3' disabled={loading}>
                {loading ?
                  <div className='text-center'>
                    <Spinner className="m-1" animation="border" role="status" style={{ width: '15px', height: '15px' }}>
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                  :
                  <>Submit <span className='ms-1'><i class="fa-solid fa-check"></i></span></>
                }
              </Button>
            </div>

          </Row>
        </Modal.Body>
      </Form>
    </div>
  )
}

export default AddAmountModal
