import React, { useContext, useEffect, useState } from 'react'
import { getTransactionCalculationAPI, getDashboardTransactionSearchAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import moment from 'moment/moment'
import MainContext from '../../Configs/Context/MainContext'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FormGroup, Button } from 'react-bootstrap'
import Pagination from '../../Configs/Pagination/Pagination'

const IMG_PREFIX = process.env.REACT_APP_SERVER_URL;

const DashboardPage = () => {

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const controllers = []
  const [loading, setLoading] = useState(false);
  const [dashboardTransactionsLoading, setDashboardTransactionsLoading] = useState(false);
  const [transactionsCalculationData, setTransactionsCalculationData] = useState([]);
  const [dashboardTransactionsData, setDashboardTransactionsData] = useState([]);
  const context = useContext(MainContext)
  const [startDate, setStartDate] = useState(formatDate(getStartDate()));
  const [endDate, setEndDate] = useState(formatDate(getEndDate()));
  const [dateRange, setDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);
  const [prevSearch, setPrevSearch] = useState();

  const userToken = localStorage.getItem('fronxUserToken')

  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState('');

  const handleDateChange = (e) => {
    setStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDateRange([e[0], e[1]])
  }

  const getTransactionCalculation = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getTransactionCalculationAPI(controller)
    if (response.status === 200) {
      setTransactionsCalculationData(response.data?.statistics)
      setTotalRecord(response.data.totalRecords);
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (userToken) {
      getTransactionCalculation()
    }
  }, [])

  // const getDashboardTransaction = async () => {
  //   setDashboardTransactionsLoading(true)
  //   const controller = new AbortController()
  //   controllers.push(controller)
  //   const response = await getDashboardTransactionAPI(controller)
  //   if (response.status === 200) {
  //     setDashboardTransactionsData(response.data?.data)
  //     setDashboardTransactionsLoading(false)
  //   } else {
  //     toast.error(response.response?.data.message);
  //     setDashboardTransactionsLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   if (!startDate && !endDate) {
  //     dashboardTransactionSearch()
  //   }
  // }, [startDate, endDate])

  const dashboardTransactionSearch = async () => {
    setDashboardTransactionsLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getDashboardTransactionSearchAPI(startDate, endDate, currentPage, limit, controller)
    if (response.status === 200) {
      // console.log("response", response)
      setDashboardTransactionsData(response.data?.data)
      setTotalRecord(response.data.totalRecords);
      setDashboardTransactionsLoading(false)
    } else {
      setDashboardTransactionsLoading(false)
    }
  }

  useEffect(() => {
    if (userToken && (startDate && endDate)) {
      dashboardTransactionSearch()
    }
  }, [startDate, endDate, currentPage, limit])

  const handleSearch = (e) => {
    e.preventDefault();
    // const hasChanged =
    //   prevSearch?.startDate !== startDate ||
    //   prevSearch?.endDate !== endDate;

    // if (hasChanged && (startDate || endDate)) {
    dashboardTransactionSearch()
    setCurrentPage(1);
    setPrevSearch({ startDate, endDate });
    // }
  }

  const handleResetFilter = () => {
    setStartDate(formatDate(getStartDate()))
    setEndDate(formatDate(getEndDate()))
    setDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    dashboardTransactionSearch()
  }

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => toast.success('URL copied to clipboard!'))
      .catch((error) => console.error('Failed to copy URL: ', error));
  }

  // const handleCopyURL = (url) => {
  //   navigator.clipboard.writeText(url)
  //     .then(() => toast.success('URL copied to clipboard!'))
  //     .catch((error) => console.error('Failed to copy URL: ', error));
  // }

  const handleImageClick = () => {
    if (context?.userSetting?.youtubeLink) {
      window.open(context?.userSetting?.youtubeLink, '_blank');
    }
  };

  const handleImageClick2 = () => {
    if (context?.userSetting?.youtubeLink2) {
      window.open(context?.userSetting?.youtubeLink2, '_blank');
    }
  };

  return (
    <>
      <div className='page-wrapper p-3 back-page'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xxl-9 col-lg-9'>
              {/* Dashboard Card */}
              <div className='row'>
                <div className="col-xxl-4 mb-3">
                  <div className="card radius-10 bg-gradient-ohhappiness">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0 text-white">{transactionsCalculationData.totalDeposit} Fronx</h5>
                        {/* <div className="ms-auto">
                          <i class="fa-solid fa-chart-simple text-white"></i>
                        </div> */}
                      </div>
                      {/* <div class="progress my-2 bg-opacity-25 bg-white" style={{ height: '4px' }}>
                        <div class="progress-bar bg-white" role="progressbar" aria-valuenow="25" style={{ width: '55%' }} aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
                      <div className="d-flex align-items-center text-white">
                        <p className="mb-0 mt-2">Total Deposit</p>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4 mb-3">
                  <div className="card radius-10 bg-gradient-ibiza">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0 text-white">{transactionsCalculationData.directRewardAmount} Fronx</h5>
                        {/* <div className="ms-auto">
                          <i className="fa-regular fa-user text-white"></i>
                        </div> */}
                      </div>
                      {/* <div class="progress my-2 bg-opacity-25 bg-white" style={{ height: '4px' }}>
                        <div class="progress-bar bg-white" role="progressbar" style={{ width: '55%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
                      <div className="d-flex align-items-center text-white">
                        <p className="mb-0 mt-2">Staking Reward</p>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-4">
                  <div className="card radius-10 bg-gradient-moonlit">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <h5 className="mb-0 text-white">{transactionsCalculationData.referralRewardAmount} Fronx</h5>
                        {/* <div className="ms-auto">
                          <i className="fa-solid fa-user-group text-white"></i>
                        </div> */}
                      </div>
                      {/* <div class="progress my-2 bg-opacity-25 bg-white" style={{ height: '4px' }}>
                        <div class="progress-bar bg-white" role="progressbar" style={{ width: '55%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div> */}
                      <div className="d-flex align-items-center text-white">
                        <p className="mb-0 mt-2">Total Referral Reward</p>
                        {/* <p class="mb-0 ms-auto">+2.2%<span><i class='bx bx-up-arrow-alt'></i></span></p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-6 d-flex justify-content-center'>
                  <div
                    className="image-container"
                    onClick={handleImageClick}
                    style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', marginBottom: '50px' }}
                  >
                    <img
                      src={IMG_PREFIX + context?.userSetting?.youtubeLinkThumbnail}
                      height="100%"
                      width="100%"
                      alt="YouTube Thumbnail"
                      style={{ display: 'block' }}
                    />
                    <div className="icon-overlay">
                      <i className="fa fa-play-circle" style={{ fontSize: '2rem', color: 'white' }}></i>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 d-flex justify-content-center'>
                  <div
                    className="image-container"
                    onClick={handleImageClick2}
                    style={{ position: 'relative', display: 'inline-block', cursor: 'pointer', marginBottom: '50px' }}
                  >
                    <img
                      src={IMG_PREFIX + context?.userSetting?.youtubeLinkThumbnail2}
                      height="100%"
                      width="100%"
                      alt="YouTube Thumbnail"
                      style={{ display: 'block' }}
                    />
                    <div className="icon-overlay">
                      <i className="fa fa-play-circle" style={{ fontSize: '2rem', color: 'white' }}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xxl-3 col-lg-3'>
              <div className="boderm" style={{ color: '#fff' }}>
                <h5>Deposit QR Code</h5>
                <div className="card radius-10 overflow-hidden w-100 mb-4 d-flex justify-content-center">

                  <div className="card-body border-m">
                    <div className="p-4 d-flex justify-content-center">
                      <img src={IMG_PREFIX + context?.userSetting?.qrImage} className="img-fluid" height={'100%'} width={'100%'} />
                    </div>
                    <div className='text-white text-center'>
                      <div className='text-wrap'>{context?.userSetting?.walletAddress} <i className="fa-regular fa-copy ms-2 text-danger" style={{ cursor: 'pointer' }} onClick={() => handleCopy(`${context?.userSetting?.walletAddress}`)}></i>
                      </div>
                    </div>
                  </div>

                </div>
                <ul style={{ marginBottom: '30px' }}>
                  <li> Deposit only Fronx token here.</li>
                  <li>Minimum deposit value : {Number(context?.userSetting?.minDepositAmount).toFixed(0)} Fronx</li>
                  <li>Your deposit reflect in 24 hours. If not please contact us.</li>
                  <li>Deposit or withdrawal will be done only from the wallet address provided by you.</li>
                </ul>

                {/* <div style={{ marginBottom: '50px', cursor: 'pointer' }} onClick={() => handleCopyURL(`${context?.userSetting?.youtubeLink}`)} className='gallery-card'>
                  <div className='gallery-content'>
                    <a href={`${context?.userSetting?.youtubeLink}`} target='_blank'>
                      <img src={IMG_PREFIX + context?.userSetting?.youtubeLinkThumbnail} height={'100%'} width={'100%'} />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardPage