import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import fronx_logo from '../../Assets/Images/final_logo.jpeg'
import { Form, Row, Col, Button, InputGroup, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';
import { EmailVerifyOTPAPI, ReSendOTPAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify';
import MainContext from '../../Configs/Context/MainContext';

const VerifyOTP = () => {

  const navigate = useNavigate()
  const controllers = []
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({});
  const [loading, setLoading] = useState(false);
  const context = useContext(MainContext)
  console.log("context", context)
  // const [timer, setTimer] = useState(300);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  // useEffect(() => {
  //   const countDown = setInterval(() => {
  //     setTimer((prev) => {
  //       if (prev <= 1) {
  //         clearInterval(countDown)
  //         setIsResendDisabled(false);
  //         return 0;
  //       }
  //       return prev - 1;
  //     })
  //   }, 1000);

  //   return () => clearInterval(countDown);
  // }, [])

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60)
  //   const seconds = time % 60;
  //   return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  // }

  const onSubmit = async (data) => {
    console.log("data", data)
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      email: data.email,
      code: data.otp,
    }

    const response = await EmailVerifyOTPAPI(body, controller)
    if (response.status === 200) {
      localStorage.setItem('fronxUserToken', response.data.token)
      toast.success(response.data.message)
      navigate(ROUTES.DASHBOARD)
      setValue('email', '');
      setLoading(false);
    } else {
      toast.error(response.response.data.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    setValue('email', context.signInData?.email)
    // setValue('otp', context.signupOTP)
  }, [context])

  const resendOTP = async () => {
    setTimeLeft(300);
    setLoading(true)
    setIsResendDisabled(true);
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      email: context?.signInData?.email,
    }

    const response = await ReSendOTPAPI(body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      navigate(ROUTES.FRONX_EMAIL_OTP)
      setValue('email', '');
      setLoading(false);
      setValue('email', context.signInData?.email)
    } else {
      toast.error(response.response.data.message);
      setLoading(false);
    }
  }

  // -------------------------------------------------------------------------------------------------
  const [timeLeft, setTimeLeft] = useState(300);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    if (!isRunning) return;

    const startTime = Date.now();
    const timer = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      setTimeLeft((prevTimeLeft) => Math.max(300 - elapsedTime, 0));

      if (300 - elapsedTime <= 0) {
        clearInterval(timer);
        setIsRunning(false);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [isRunning]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (timeLeft > 0) {
      setIsRunning(true);
    } else {
      setIsResendDisabled(false)
    }
  }, [timeLeft])
  // -------------------------------------------------------------------------------------------------

  return (
    <>
      <div id="main-wrapper" className="login-register">
        <div className="container-fluid px-0">
          <div className="row g-0 min-vh-100">
            <div className="col-md-6 d-flex flex-column  justify-content-center" style={{ backgroundColor: '#414346' }}>
              <div className="">
                <div className=" d-flex flex-column login-left-side">
                  <div className="row g-0">
                    <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                      <div className="logo mt-5 mb-5 mb-md-0 text-center dash-logo">
                        <img src={fronx_logo} alt="" className='logo-img' height={'140px'} width={'200px'} />
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                      <h1 className="mb-4" style={{ color: '#fff' }}>Welcome back!</h1>
                      <p className="mb-5" style={{ color: '#fff', fontSize: '1.125rem' }}>We are glad to see you again! Get access to your Orders, Wishlist and Recommendations.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="container my-auto py-5">
                <div className="row g-0">
                  <div className="col-11 col-sm-10 col-lg-9 col-xl-8 mx-auto">
                    <h3 className="fw-bolder mb-4">Verify OTP</h3>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={12}>
                          <Form.Group className='mb-2'>
                            <Form.Label>Email Address<span className='text-danger'>*</span></Form.Label>
                            <Form.Control placeholder='Enter Your Email Address' {...register('email', {
                              required: "Email is Required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please Enter Valid Email",
                              }
                            })} />
                            <p className='Err-msg'>{errors.email ? <div>{`${errors?.email?.message}`}</div> : ""}</p>
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group className="mb-2">
                            <Form.Label>OTP<span className='text-danger'>*</span></Form.Label>
                            <InputGroup>
                              <Form.Control type='text'
                                {...register("otp", { required: true })} placeholder='Enter OTP' autoComplete='false' />
                            </InputGroup>
                            <p className='Err-msg'>{errors.otp && <p>OTP is required</p>}</p>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="mt-2 d-flex justify-content-between align-items-center">
                        <div className="">
                          <strong>Time Remaining: {timeLeft > 0 ? formatTime(timeLeft) : "00:00"}</strong>
                        </div>
                        <div className="text-end">
                          <Button className='text-decoration-underline'
                            disabled={isResendDisabled}
                            style={{ backgroundColor: 'transparent', border: 'none' }} onClick={resendOTP}>
                            <Link to={ROUTES.FRONX_RESEND_OTP}>
                              Resend OTP
                            </Link>
                          </Button>
                        </div>
                      </div>

                      <div className="d-grid my-4">
                        <Button type='submit' className='login-submit-btn' disabled={loading}>
                          {
                            loading ?
                              <div className='text-center'>
                                <Spinner className="m-1" animation="border" role="status" style={{ height: "20px", width: "20px" }}>
                                </Spinner>
                              </div>
                              :
                              <>Verify OTP</>
                          }
                        </Button>
                      </div>
                    </Form>
                    {/* <p className="text-center text-muted mb-0">Don't have an account? <Link to={ROUTES.FRONX_SIGNUP}>Sign Up</Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyOTP
