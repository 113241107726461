import React, { useEffect, useState } from 'react'
import { getSettingAPI, updateSettingAPI } from '../../Configs/Utils/Apis/UserApis';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Form, FormGroup, InputGroup } from 'react-bootstrap'
import editBtn from '../../Assets/Images/editBtn.png'
import { Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const IMG_PREFIX = process.env.REACT_APP_SERVER_URL

const Setting = () => {

  const controllers = []
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({});
  const [settingDetails, setSettingDetails] = useState('');
  const [fileList, setFileList] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [originFileList, setOriginFileList] = useState([])

  // Youtube Link
  const [fileListOne, setFileListOne] = useState([])
  const [previewOpenOne, setPreviewOpenOne] = useState(false);
  const [previewImageOne, setPreviewImageOne] = useState('');
  const [previewTitleOne, setPreviewTitleOne] = useState('');
  const [originFileListOne, setOriginFileListOne] = useState([])

  // Youtube Link 2
  const [fileListTwo, setFileListTwo] = useState([])
  const [previewOpenTwo, setPreviewOpenTwo] = useState(false);
  const [previewImageTwo, setPreviewImageTwo] = useState('');
  const [previewTitleTwo, setPreviewTitleTwo] = useState('');
  const [originFileListTwo, setOriginFileListTwo] = useState([])

  useEffect(() => {
    if (settingDetails) {
      setValue('withdraw_period', settingDetails.walletWithdrawalRequestTimePeriod)
      setValue('direct_reward', settingDetails.directRewardPercentage)
      setValue('credit_period', settingDetails.rewardCreditTimePeriod)
      setValue('referral_percentage', settingDetails.referralRewardPercentage)
      setValue('wallet_address', settingDetails.walletAddress)
      setValue('youtube_link', settingDetails.youtubeLink)
      setValue('youtube_thumbnail', settingDetails.youtubeLinkThumbnail)
      setValue('min_withdrawable_amount', settingDetails.minWithdrawableAmount)
      setValue('minDepositAmount', settingDetails.minDepositAmount)
      // setValue('max_withdrawable_amount', settingDetails.maxWithdrawableAmount)
      setValue('youtube_link_2', settingDetails.youtubeLink2)

      const image = IMG_PREFIX + settingDetails.qrImage;
      setFileList([
        {
          uid: '-1',
          name: 'gallery_image',
          status: 'done',
          url: image,
        },
      ]);

      const image2 = IMG_PREFIX + settingDetails.youtubeLinkThumbnail;
      setFileListOne([
        {
          uid: '-1',
          name: 'gallery_image',
          status: 'done',
          url: image2,
        },
      ]);

      const image3 = IMG_PREFIX + settingDetails.youtubeLinkThumbnail2;
      setFileListTwo([
        {
          uid: '-1',
          name: 'gallery_image',
          status: 'done',
          url: image3,
        },
      ]);
    }
  }, [settingDetails])

  const getSetting = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await getSettingAPI(controller)
    if (response.status === 200) {
      // console.log("response", response)
      setSettingDetails(response.data.data)
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    getSetting()
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)

    const formData = new FormData()
    formData.append('walletWithdrawalRequestTimePeriod', data.withdraw_period)
    formData.append('directRewardPercentage', data.direct_reward)
    formData.append('rewardCreditTimePeriod', data.credit_period)
    formData.append('referralRewardPercentage', data.referral_percentage)
    formData.append('minWithdrawableAmount', data.min_withdrawable_amount)
    formData.append('walletAddress', data.wallet_address)
    formData.append('youtubeLink', data.youtube_link)
    formData.append('youtubeLink2', data.youtube_link_2)
    formData.append('minDepositAmount', data.minDepositAmount)
    // formData.append('maxWithdrawableAmount', data.max_withdrawable_amount)

    for (let i = 0; i < originFileListOne?.length; i++) {
      formData.append('youtubeLinkThumbnail', originFileListOne[i])
    }

    for (let i = 0; i < originFileListTwo?.length; i++) {
      formData.append('youtubeLinkThumbnail2', originFileListTwo[i])
    }

    for (let i = 0; i < originFileList?.length; i++) {
      formData.append('qrImage', originFileList[i])
    }

    const controller = new AbortController()
    controllers.push(controller)

    if (fileListOne?.length === 0) {
      toast.error("Youtube Thumbnail 1 Image Is Required")
      setLoading(false);
    } else if (fileListTwo?.length === 0) {
      toast.error("Youtube Thumbnail 2 Image Is Required")
      setLoading(false);
    } else if (fileList?.length === 0) {
      toast.error("Qr Code Image Is Required")
      setLoading(false);
    } else {
      const response = await updateSettingAPI(formData, controller)
      if (response.status === 200) {
        toast.success(response.data.message)
        setFileList([])
        setFileListOne([])
        setFileListTwo([])
        getSetting()
        setLoading(false)
      } else {
        toast.error(response.response?.data.message);
        setLoading(false)
      }
    }

  }

  // Image 
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChangeImage = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    let originarr = [];
    newFileList.forEach((item) => {
      if (item.originFileObj) {
        originarr.push(item.originFileObj);
      }
    });
    setOriginFileList(originarr);
  };

  const customRequestMetaImage = async ({ file, onSuccess, onError }) => {
    try {
      const fileUrl = URL.createObjectURL(file);
      const newFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: fileUrl,
      };
      setFileList((prevFileList) => [...prevFileList, newFile]);
      onSuccess(newFile);
    } catch (error) {
      onError(error);
    }
  };

  const uploadButtonOfMetaImage = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleCancel = () => setPreviewOpen(false);

  // Thumbnail Image
  const handlePreviewOne = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setPreviewImageOne(file.url || file.preview);
    setPreviewOpenOne(true);
    setPreviewTitleOne(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChangeImageOne = ({ fileList: newFileList }) => {
    setFileListOne(newFileList);
    let originarr = [];
    newFileList.forEach((item) => {
      if (item.originFileObj) {
        originarr.push(item.originFileObj);
      }
    });
    setOriginFileListOne(originarr);
  };

  const customRequestMetaImageOne = async ({ file, onSuccess, onError }) => {
    try {
      const fileUrl = URL.createObjectURL(file);
      const newFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: fileUrl,
      };
      setFileListOne((prevFileList) => [...prevFileList, newFile]);
      onSuccess(newFile);
    } catch (error) {
      onError(error);
    }
  };

  const uploadButtonOfMetaImageOne = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleCancelOne = () => setPreviewOpenOne(false);

  // Thumbnail Image 2
  const handlePreviewTwo = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setPreviewImageTwo(file.url || file.preview);
    setPreviewOpenTwo(true);
    setPreviewTitleTwo(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChangeImageTwo = ({ fileList: newFileList }) => {
    setFileListTwo(newFileList);
    let originarr = [];
    newFileList.forEach((item) => {
      if (item.originFileObj) {
        originarr.push(item.originFileObj);
      }
    });
    setOriginFileListTwo(originarr);
  };

  const customRequestMetaImageTwo = async ({ file, onSuccess, onError }) => {
    try {
      const fileUrl = URL.createObjectURL(file);
      const newFile = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: fileUrl,
      };
      setFileListTwo((prevFileList) => [...prevFileList, newFile]);
      onSuccess(newFile);
    } catch (error) {
      onError(error);
    }
  };

  const uploadButtonOfMetaImageTwo = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleCancelTwo = () => setPreviewOpenTwo(false);

  return (
    <div>
      <div className="page-wrapper p-3 profile-main back-page">
        <div className="page-content">
          <div className="row">
            <div className="col-md-12 text-white">
              <h1>Setting</h1>
            </div>
          </div>

          <div className='row justify-content-center mt-4'>
            <div className='col-10'>
              <div className="card w-100 mb-5">
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row g-3'>
                      <div className='col-md-6'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Wallet Withdraw Request Period</label>
                            </div>
                            <div className='col-md-8'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='number'
                                    {...register("withdraw_period", { required: true })} placeholder='Enter Wallet Withdraw Request Period' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className='Err-msg'>{errors.withdraw_period && <p>Wallet Withdraw Request Period is required</p>}</p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Direct Reward Percentage(%)</label>
                            </div>
                            <div className='col-md-8'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    step="0.01"
                                    {...register("direct_reward", {
                                      required: "Direct Reward Percentage is required",
                                      validate: (value) => {

                                        const valueAsString = value.toString();

                                        if (value <= 0) return "Value must be greater than 0";
                                        if (value > 100) return "Value must not exceed 100";

                                        if (!/^\d+(\.\d{0,2})?$/.test(valueAsString)) {
                                          return "Value must have at most 2 decimal places";
                                        }

                                        return true;
                                      },
                                    })}
                                    placeholder="Enter Direct Reward Percentage"
                                    className="form-control quantity-search"
                                    onInput={(e) => {
                                      const value = e.target.value;
                                      if (value.includes('.') && value.split('.')[1].length > 2) {
                                        e.target.value = value.slice(0, value.indexOf('.') + 3);
                                      }
                                    }}
                                  />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className="Err-msg">
                                  {errors.direct_reward && <span>{errors.direct_reward.message}</span>}
                                </p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Reward Credit Time Period</label>
                            </div>
                            <div className='col-md-8'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='number'
                                    {...register("credit_period", { required: true })} placeholder='Enter Reward Credit Time Period' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className='Err-msg'>{errors.credit_period && <p>Reward Credit Time Period is required</p>}</p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Referral Reward Percentage(%)</label>
                            </div>
                            <div className='col-md-8'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control
                                    type='number'
                                    step="0.01"
                                    {...register("referral_percentage", {
                                      required: "Direct Reward Percentage is required",
                                      validate: (value) => {

                                        const valueAsString = value.toString();

                                        if (value <= 0) return "Value must be greater than 0";
                                        if (value > 100) return "Value must not exceed 100";

                                        if (!/^\d+(\.\d{0,2})?$/.test(valueAsString)) {
                                          return "Value must have at most 2 decimal places";
                                        }

                                        return true;
                                      },
                                    })}
                                    placeholder='Enter Referral Reward Percentage' className="form-control quantity-search"
                                    onInput={(e) => {
                                      const value = e.target.value;
                                      if (value.includes('.') && value.split('.')[1].length > 2) {
                                        e.target.value = value.slice(0, value.indexOf('.') + 3);
                                      }
                                    }}
                                  />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className="Err-msg">
                                  {errors.referral_percentage && <span>{errors.referral_percentage.message}</span>}
                                </p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Min Withdrawable Amount</label>
                            </div>
                            <div className='col-md-8'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='number'
                                    {...register("min_withdrawable_amount", {
                                      required: "Min Withdrawable Amount is required",
                                      validate: (value) =>
                                        value > 0 || "Value must be greater than 0",
                                    })} placeholder='Enter Min Withdrawable Amount' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className="Err-msg">
                                  {errors.min_withdrawable_amount && (
                                    <p>{errors.min_withdrawable_amount.message}</p>
                                  )}
                                </p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Min Deposit Amount</label>
                            </div>
                            <div className='col-md-8'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='number'
                                    {...register("minDepositAmount", {
                                      required: "Min Deposit Amount is required",
                                      validate: (value) =>
                                        value > 0 || "Value must be greater than 0",
                                    })} placeholder='Enter Min Deposit Amount' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className="Err-msg">
                                  {errors.minDepositAmount && (
                                    <p>{errors.minDepositAmount.message}</p>
                                  )}
                                </p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className='col-md-6'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Max Withdrawable Amount</label>
                            </div>
                            <div className='col-md-8'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='number'
                                    {...register("max_withdrawable_amount", {
                                      required: "Min Withdrawable Amount is required",
                                      validate: (value) =>
                                        value > 0 || "Value must be greater than 0",
                                    })} placeholder='Enter Max Withdrawable Amount' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className="Err-msg">
                                  {errors.max_withdrawable_amount && (
                                    <p>{errors.max_withdrawable_amount.message}</p>
                                  )}
                                </p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className='col-12'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-2">
                              <label className='text-white'>Wallet Address</label>
                            </div>
                            <div className='col-md-10'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='text'
                                    {...register("wallet_address", { required: true })} placeholder='Enter Wallet Address' className="form-control quantity-search text-wrap" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className='Err-msg'>{errors.wallet_address && <p>Wallet Address is required</p>}</p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-12'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-2">
                              <label className='text-white'>Youtube Link 1</label>
                            </div>
                            <div className='col-md-10'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='text'
                                    {...register("youtube_link", { required: true })} placeholder='Enter Youtube Link' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className='Err-msg'>{errors.youtube_link && <p>Youtube Link is required</p>}</p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-12'>
                        <div className='box'>
                          <div className='row'>
                            <div className="col-md-2">
                              <label className='text-white'>Youtube Link 2</label>
                            </div>
                            <div className='col-md-10'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='text'
                                    {...register("youtube_link_2", { required: true })} placeholder='Enter Youtube Link 2' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className='Err-msg'>{errors.youtube_link_2 && <p>Youtube Link 2 is required</p>}</p>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div>
                          <FormGroup id='image' label='image'>
                            <div className='text-white d-flex mb-2'>
                              <span>
                                Youtube Thumbnail 1(528 * 325)
                              </span>
                              <span class="d-flex align-items-center ps-2">
                                <img src={editBtn} width={'22px'} />
                              </span>
                            </div>

                            <Upload
                              listType='picture-card'
                              fileList={fileListOne}
                              onPreview={handlePreviewOne}
                              onChange={handleChangeImageOne}
                              customRequest={customRequestMetaImageOne}
                              className="custom-upload"
                            >
                              {fileListOne?.length >= 1 ? null : uploadButtonOfMetaImageOne}
                            </Upload>
                            <Modal open={previewOpenOne} title={previewTitleOne} footer={null} onCancel={handleCancelOne} centered>
                              <img alt="example" style={{ width: '100%' }} src={previewImageOne} />
                            </Modal>
                          </FormGroup>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div>
                          <FormGroup id='image' label='image'>
                            <div className='text-white d-flex mb-2'>
                              <span>
                                Youtube Thumbnail 2(528 * 325)
                              </span>
                              <span class="d-flex align-items-center ps-2">
                                <img src={editBtn} width={'22px'} />
                              </span>
                            </div>

                            <Upload
                              listType='picture-card'
                              fileList={fileListTwo}
                              onPreview={handlePreviewTwo}
                              onChange={handleChangeImageTwo}
                              customRequest={customRequestMetaImageTwo}
                              className="custom-upload"
                            >
                              {fileListTwo?.length >= 1 ? null : uploadButtonOfMetaImageTwo}
                            </Upload>
                            <Modal open={previewOpenTwo} title={previewTitleTwo} footer={null} onCancel={handleCancelTwo} centered>
                              <img alt="example" style={{ width: '100%' }} src={previewImageTwo} />
                            </Modal>
                          </FormGroup>
                        </div>
                      </div>

                      <div className='col-md-3'>
                        <div>
                          <FormGroup id='image' label='image'>
                            <div className='text-white d-flex mb-2'>
                              <span>
                                Qr Code Image(238 * 238)
                              </span>
                              <span class="d-flex align-items-center ps-2">
                                <img src={editBtn} width={'22px'} />
                              </span>
                            </div>

                            <Upload
                              listType='picture-card'
                              fileList={fileList}
                              onPreview={handlePreview}
                              onChange={handleChangeImage}
                              customRequest={customRequestMetaImage}
                              className="custom-upload"
                            >
                              {fileList?.length >= 1 ? null : uploadButtonOfMetaImage}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} centered>
                              <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                          </FormGroup>
                        </div>
                      </div>

                      <div className='row mt-2'>
                        <div className="text-end">
                          <div className="mt-3">
                            <button type="submit" className="withdrawal-active-btn" style={{ padding: '7px 40px' }}>Update Profile</button>
                          </div>
                        </div>
                      </div>

                      {/* <div className='box'>
                          <div className='row'>
                            <div className="col-md-4">
                              <label className='text-white'>Youtube Thumbnail</label>
                            </div>
                            <div className='col-md-6'>
                              <Form.Group>
                                <InputGroup>
                                  <Form.Control type='text'
                                    {...register("youtube_thumbnail", { required: true })} placeholder='Enter Youtube Thumbnail' className="form-control quantity-search" />
                                  <div class="d-flex align-items-center ps-2">
                                    <img src={editBtn} width={'70%'} />
                                  </div>
                                </InputGroup>
                                <p className='Err-msg'>{errors.youtube_thumbnail && <p>Youtube Thumbnail is required</p>}</p>
                              </Form.Group>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setting;