import React, { useContext, useEffect, useState } from 'react'
// import login_page_logo from '../../Assets/Images/login_page_logo.png'
import fronx_logo from '../../Assets/Images/final_logo.jpeg'
import { useForm } from 'react-hook-form';
import { Form, Row, Col, Button, InputGroup, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';
import { LoginAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify';
import MainContext from '../../Configs/Context/MainContext';

const FronxLogin = () => {

  const navigate = useNavigate()
  const controllers = []
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const context = useContext(MainContext)
  const userToken = localStorage.getItem('fronxUserToken')

  const onSubmit = async (data) => {
    // console.log("data", data)
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const body = {
      email: data.email.trim(),
      password: data.password
    }
    const response = await LoginAPI(body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      localStorage.setItem('fronxUserToken', response.data.token)
      setValue('email', '')
      setValue('password', '')
      if (response.data.data.role === "admin") {
        navigate(ROUTES.USER_LIST.LIST)
      } else {
        navigate(ROUTES.DASHBOARD)
      }
      setLoading(false)
    } else {
      toast.error(response.response.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (userToken) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [navigate, userToken])

  return (
    <>
      <div id="main-wrapper" className="login-register">
        <div className="container-fluid px-0">
          <div className="row g-0 min-vh-100">
            <div className="col-md-6 d-flex flex-column  justify-content-center" style={{ backgroundColor: '#414346' }}>
              <div className="">
                <div className=" d-flex flex-column login-left-side">
                  <div className="row g-0">
                    <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                      <div className="logo mt-5 mb-5 mb-md-0 text-center dash-logo">
                        <img src={fronx_logo} alt="" className='logo-img' height={'140px'} width={'200px'} />
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-11 col-sm-10 col-lg-9 mx-auto">
                      <h1 className="mb-4" style={{ color: '#fff' }}>Welcome back!</h1>
                      <p className="mb-5" style={{ color: '#fff', fontSize: '1.125rem' }}>We are glad to see you again! Get access to your Orders, Wishlist and Recommendations.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="container my-auto py-5">
                <div className="row g-0">
                  <div className="col-11 col-sm-10 col-lg-9 col-xl-8 mx-auto">
                    <h3 className="fw-bolder mb-4">Log In</h3>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={12}>
                          <Form.Group className='mb-2'>
                            <Form.Label>Email Address<span className='text-danger'>*</span></Form.Label>
                            <Form.Control placeholder='Enter Your Email Address' {...register('email', {
                              required: "Email is Required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please Enter Valid Email",
                              }
                            })} />
                            <p className='Err-msg'>{errors.email ? <div>{`${errors?.email?.message}`}</div> : ""}</p>
                          </Form.Group>
                        </Col>

                        <Col md={12}>
                          <Form.Group className='mb-2'>
                            <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
                            <InputGroup>
                              <Form.Control type={passwordVisible ? 'text' : 'password'} placeholder='Enter Password' {...register('password', { required: true })} />

                              <InputGroup.Text className='bg-transparent shadow-none PasswordVisible ' onClick={() => { setPasswordVisible(!passwordVisible) }} style={{ width: '46px' }}>
                                <i className={passwordVisible ? "fa fa-eye" : "fa fa-eye-slash"} />
                              </InputGroup.Text>
                            </InputGroup>
                            {/* <p className='Err-msg'>{errors.password ? <div>{`${errors?.password?.message}`}</div> : ""}</p> */}
                            <p className='Err-msg'>{errors.password && <p>Password is required</p>}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="mt-2">
                        {/* <div className="col-md-6">
                          <input type="checkbox" /> <span className="">Remember Me</span>
                        </div> */}
                        <div className="text-end">
                          <Button className='text-decoration-underline' style={{ backgroundColor: 'transparent', border: 'none' }}>
                            <Link to={ROUTES.FORGOT_PASSWORD_PAGE.LIST} onClick={() => context.setSignInData(watch('email'))}>
                              Forgot Password ?
                            </Link>
                          </Button>
                        </div>
                      </div>
                      <div className="d-grid my-4">
                        <Button type='submit' className='login-submit-btn mt-3' disabled={loading}>
                          {
                            loading ?
                              <div className='text-center'>
                                <Spinner className="m-1" animation="border" role="status" style={{ height: "20px", width: "20px" }}>
                                </Spinner>
                              </div>
                              :
                              <>Login</>
                          }
                        </Button>
                      </div>
                    </Form>
                    <p className="text-center text-muted mb-0">Don't have an account? <Link to={ROUTES.FRONX_SIGNUP}>Sign Up</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FronxLogin