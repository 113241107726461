import React, { useEffect, useState } from 'react'
import { AdminDepositTransactionAPI, deleteDepositAPI } from '../../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import moment from 'moment'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FormGroup, Button } from 'react-bootstrap'
import Pagination from '../../Configs/Pagination/Pagination'
import CustomModal from '../../Configs/CustomModal/CustomModal';
import DeleteDepositModal from './DeleteDepositModal';

const AdminDeposit = () => {

  const getStartDate = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    return lastMonth;
  };

  const getEndDate = () => {
    const today = new Date();
    return today;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const controllers = []
  const [loading, setLoading] = useState(false);
  const [adminDepositTransactionsData, setAdminDepositTransactionsData] = useState([]);
  const [startDate, setStartDate] = useState(formatDate(getStartDate()));
  const [endDate, setEndDate] = useState(formatDate(getEndDate()));
  const [dateRange, setDateRange] = useState([formatDate(getStartDate()), formatDate(getEndDate())]);
  const [prevSearch, setPrevSearch] = useState();
  const [deleteDepositModalStatus, setDeleteDepositModalStatus] = useState(false);
  const [depositId, setDepositId] = useState('');

  const [totalRecord, setTotalRecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPage, setTotalPage] = useState('');

  const handleDateChange = (e) => {
    setStartDate(e[0]?.getFullYear() + '-' + (e[0]?.getMonth() < 9 ? '0' + (e[0]?.getMonth() + 1) : e[0]?.getMonth() + 1) + '-' + (e[0]?.getDate() <= 9 ? "0" + (e[0]?.getDate()) : e[0]?.getDate()))
    setEndDate(e[1]?.getFullYear() + '-' + (e[1]?.getMonth() < 9 ? "0" + (e[1]?.getMonth() + 1) : e[1]?.getMonth() + 1) + '-' + (e[1]?.getDate() <= 9 ? "0" + (e[1]?.getDate()) : e[1]?.getDate()))
    setDateRange([e[0], e[1]])
  }

  const AdminDepositTransaction = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)
    const response = await AdminDepositTransactionAPI(startDate, endDate, currentPage, limit, controller)
    if (response.status === 200) {
      // console.log("response", response)
      setAdminDepositTransactionsData(response.data?.data)
      setTotalRecord(response.data.totalRecords);
      setLoading(false)
    } else {
      toast.error(response.response?.data.message);
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!startDate || !endDate) {
      AdminDepositTransaction()
    }
  }, [startDate, endDate, currentPage, limit])

  useEffect(() => {
    if (startDate && endDate) {
      AdminDepositTransaction()
    }
  }, [currentPage, limit, startDate, endDate])

  const handleSearch = (e) => {
    e.preventDefault();
    AdminDepositTransaction()
    setCurrentPage(1);
    setPrevSearch({ startDate, endDate });
  }

  const handleResetFilter = () => {
    setStartDate(formatDate(getStartDate()))
    setEndDate(formatDate(getEndDate()))
    setDateRange([formatDate(getStartDate()), formatDate(getEndDate())])
    AdminDepositTransaction()
  }

  useEffect(() => {
    if (totalRecord || totalRecord === 0) {
      var page = totalRecord / limit;
      setTotalPage(page);
    }
  }, [totalRecord]);

  return (
    <div className='page-wrapper p-3 back-page'>
      <div className='page-content'>
        <div class="row mb-1">
          <div className='col-12 mb-4'>
            <div>
              <form className='searchMain gap-2 mb-3' onSubmit={handleSearch}>
                <div className='d-flex justify-content-end align-items-end flex-wrap gap-2 mb-2'>
                  <div className='dashDateSearch'>
                    <label style={{ fontSize: '11px', color: '#fff' }} className='fw-bolder'>Created Date</label>
                    <FormGroup>
                      <DateRangePicker
                        className='dateSearch'
                        onChange={handleDateChange}
                        value={dateRange}
                        showLeadingZeros
                        format="dd/MM/y"
                      />
                    </FormGroup>
                  </div>
                  {/* <Button type='submit' variant="secondary">
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </Button> */}
                  <Button variant="secondary" onClick={handleResetFilter}>
                    <i class="fa-solid fa-retweet"></i>
                  </Button>
                </div>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table mb-0 table-modern table-hover mb-0 transaction-table">
                <thead style={{ backgroundColor: '#0B1830' }}>
                  <tr>
                    <th scope="col" className='text-white'>No.</th>
                    <th scope="col" className='text-white'>Name</th>
                    <th scope="col" className='text-white'>Amount</th>
                    <th scope="col" className='text-white'>Deposit Date</th>
                    <th scope="col" className='text-white'>Unlock Date</th>
                    <th scope="col" className='text-white'>Created Date</th>
                    <th scope="col" className='text-white text-end'>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !loading && adminDepositTransactionsData?.length > 0 ? (
                      adminDepositTransactionsData.map((item, index) => {
                        let formattedTime = moment(item.createdAt).format('DD/MM/YYYY')
                        let depositTime = item.depositDate ? moment(item.depositDate).format('DD/MM/YYYY') : '-'
                        let lockingTime = item.lockingDate ? moment(item.lockingDate).format('DD/MM/YYYY') : '-'
                        return (
                          <>
                            <tr key={index}>
                              <td className='text-white'>{index + 1}</td>
                              <td className='text-white'>{item?.user?.name}</td>
                              <td className='text-white'>{item.amount}</td>
                              <td className='text-white'>{depositTime}</td>
                              <td className='text-white'>{lockingTime}</td>
                              <td className='text-white'>{formattedTime}</td>
                              <td scope='col' className='text-end'>
                                <Button variant='danger' className='ms-2' onClick={() => { setDeleteDepositModalStatus(true); setDepositId(item.id) }}>
                                  <i className='fa-duotone fa-solid fa-trash fa-fw' />
                                </Button>
                              </td>
                            </tr>
                          </>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center text-white">
                          {loading ? <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div> : "No data found"}
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
              <div className='centerListPagination mt-2'>
                {!loading && totalPage > 1 &&
                  <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                }
              </div>
          </div>
        </div>
      </div>
      <CustomModal display={deleteDepositModalStatus} handleClose={() => setDeleteDepositModalStatus(!deleteDepositModalStatus)} size='md' className='userModal doctor-slot' top backdrop="static">
        <DeleteDepositModal
          setDeleteDepositModalStatus={setDeleteDepositModalStatus}
          depositId={depositId}
          AdminDepositTransaction={AdminDepositTransaction}
        />
      </CustomModal>
    </div>
  )
}

export default AdminDeposit
