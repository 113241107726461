import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { deleteDepositAPI } from '../../Configs/Utils/Apis/UserApis';

const DeleteDepositModal = ({ setDeleteDepositModalStatus, depositId, AdminDepositTransaction }) => {

  const [loading, setLoading] = useState(false)
  const controllers = [];

  const handleDelete = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)

    const response = await deleteDepositAPI(depositId, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      setDeleteDepositModalStatus(false)
      setLoading(false)
      AdminDepositTransaction()
    } else {
      toast.error(response.response?.data?.message);
      setLoading(false)
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <h5>Confirmation Alert!</h5>
      </Modal.Header>
      <Modal.Body>
        <p className='lead'>Are you sure to remove this Deposit ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className='mt-3 me-2' disabled={loading} variant="secondary" onClick={() => setDeleteDepositModalStatus(false)}>No <i class="fa-solid fa-xmark"></i></Button>
        <Button className='mt-3 me-2' disabled={loading} variant="secondary" onClick={handleDelete}>Yes <span className='ms-1'><i class="fa-solid fa-check"></i></span></Button>
      </Modal.Footer>
    </>
  )
}

export default DeleteDepositModal