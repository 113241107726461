import React, { useContext, useEffect, useState } from 'react'
import user_logo from '../Assets/Images/dummy-image.jpg'
import Dropdown from 'react-bootstrap/Dropdown'
import MainContext from '../Configs/Context/MainContext'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ROUTES from '../Configs/Routes'
import { getUserSettingAPI, getProfileAPI, GetAllNotificationAPI, ReadAllNotificationAPI, ReadOneNotificationAPI } from '../Configs/Utils/Apis/UserApis'
import { toast } from 'react-toastify'
import Offcanvas from 'react-bootstrap/Offcanvas';
import moment from 'moment'

const Header = () => {

	const controllers = []
	const context = useContext(MainContext)
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false);
	const [loadingNotification, setLoadingNotification] = useState(false);
	const [loadingReadNotification, setLoadingReadNotification] = useState(false);
	// const [profileDetails, setProfileDetails] = useState('');
	const userToken = localStorage.getItem('fronxUserToken')
	const [allNotificationData, setAllNotificationData] = useState([]);
	const [unreadCount, setUnreadCount] = useState();

	// -------------------------------------------------------------------
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	// -------------------------------------------------------------------

	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


	const handleMobileMenuToggle = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	const handleToggle = () => {
		context.setIsToggled(!context.isToggled)
	}

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const getQrCode = async () => {
		setLoading(true)
		const controller = new AbortController()
		controllers.push(controller)
		const response = await getUserSettingAPI(controller)
		if (response.status === 200) {
			context.setUserSetting(response.data?.data)
			setLoading(false)
		} else {
			toast.error(response.response?.data.message);
			setLoading(false)
		}
	}

	const getProfile = async () => {
		setLoading(true)
		const controller = new AbortController()
		controllers.push(controller)
		const response = await getProfileAPI(controller)
		if (response.status === 200) {
			// console.log("response", response)
			// setProfileDetails(response.data.data)
			context.setProfile(response.data?.data)
			setLoading(false)
		} else {
			// console.log("response", response)
			toast.error(response.response?.data.message);
			setLoading(false)
		}
	}

	useEffect(() => {
		if (userToken) {
			getProfile()
		}
	}, [])

	useEffect(() => {
		if (context?.profile?.role === "user") {
			getQrCode()
		}
	}, [context?.profile?.role])

	const GetAllNotification = async () => {
		setLoadingNotification(true)
		const controller = new AbortController()
		controllers.push(controller)
		const response = await GetAllNotificationAPI(controller)
		if (response.status === 200) {
			// console.log("response", response)
			setAllNotificationData(response.data?.data)
			setUnreadCount(response.data.totalUnreadNotification);
			setLoadingNotification(false)
		} else {
			toast.error(response.response?.data.message);
			setLoadingNotification(false)
		}
	}

	useEffect(() => {
		if (userToken) {
			GetAllNotification()
		}
	}, [])

	const handleReadAllNotification = async () => {
		setLoadingReadNotification(true)
		const controller = new AbortController()
		controllers.push(controller)
		const response = await ReadAllNotificationAPI(controller)
		if (response.status === 200) {
			setLoadingReadNotification(false)
			GetAllNotification()
			setOffcanvasStatus(false)
		} else {
			setLoadingReadNotification(false)
		}
	}

	const handleReadNotification = async (type, id, userid) => {
		setLoadingReadNotification(true)
		const controller = new AbortController()
		controllers.push(controller)
		const response = await ReadOneNotificationAPI(id, controller)
		if (response.status === 200) {
			setLoadingReadNotification(false)
			GetAllNotification()
			setOffcanvasStatus(false)
			if (type === "WithdrawalRequest") {
				navigate(ROUTES.ADMIN_WITHDRAWAL)
			} else if (type === "WalletAddressRequest") {
				navigate(ROUTES.USER_LIST.LIST + userid)
			}
		} else {
			setLoadingReadNotification(false)
		}
	}

	return (
		<header>
			<div className="topbar">
				<nav className="navbar navbar-expand">
					<div className="mobile-toggle-menu" onClick={handleToggle}>
						<i className="fa-solid fa-bars dropdown-toggler"></i>
					</div>

					<div className='d-flex justify-content-start align-items-center w-100'>

						{
							context?.profile?.role === "user" &&
							<div className='text-white'>
								<span className='amount-text ms-3'>Wallet : {context?.profile?.wallet} Fronx</span>
							</div>
						}

					</div>

					<div className='d-flex align-items-center justify-content-end'>
						{
							context?.profile?.role === "admin" &&
							<>
								<div className="position-relative col-auto main-dash" style={{ cursor: 'pointer', padding: '7px 12px', borderRadius: '10px' }} onClick={() => setOffcanvasStatus(true)} >
									<i className={`${unreadCount ? 'dashNotiIcon fa-regular fa-bell text-white' : 'dashNotiIvon1 fa-regular fa-bell text-white'}`}></i>
									{unreadCount ? <span className='statusChange'>{unreadCount}</span> : ''}
								</div>

								<Offcanvas
									id='notificationCanvas'
									titleId='offcanvasExampleLabel'
									placement='end'
									show={offcanvasStatus}
									onHide={() => setOffcanvasStatus(false)}
								>
									<Offcanvas.Header closeButton>
										<Offcanvas.Title>Notifications</Offcanvas.Title>
									</Offcanvas.Header>
									<Offcanvas.Body>
										<div className='mb-2 d-flex justify-content-between'>
											<span>You have {unreadCount} unread notification</span>
											{
												allNotificationData?.length > 0 ? <button type="button" className="readAll fw-semibold" onClick={() => { handleReadAllNotification() }}>Read All</button> : ""
											}
										</div>
										{
											!loadingNotification && allNotificationData.length > 0 ? (
												allNotificationData.map((item) => {
													let timeAgo = moment(item.createdAt).fromNow();
													return (
														<>
															<div style={{ cursor: 'pointer' }} onClick={() => handleReadNotification(item.notificationType, item.id, item.userId)}>
																<div className={item.notificationType === "WalletAddressRequest" ? "WalletAddressRequest mt-1" :
																	item.notificationType === "WithdrawalRequest" ? 'WithdrawalRequest mt-1' : ''
																}>
																	<div >{item.title}</div><br />
																	<div className='text-end'>{timeAgo}</div>
																</div>
															</div>
														</>
													)
												})
											) : (
												<tr className='text-center'>
													<td colSpan={12} className="text-center text-white">
														{
															loadingNotification ? <div className="spinner-border text-primary" role="status">
																<span className="sr-only">Loading...</span>
															</div> : "No data found"}
													</td>
												</tr>
											)
										}
									</Offcanvas.Body>
								</Offcanvas >
							</>
						}

						<Dropdown>
							<Dropdown.Toggle variant="" id="dropdown-basic" className='d-flex align-items-center'>
								<Link to="#" className="nav-link d-flex align-items-center" role="button">
									<img src={user_logo} className="user-img" alt="user avatar" />
									<div className="user-info">
										<p className="user-name mb-0 ms-2" style={{ color: '#fff' }}>
											{context?.profile?.name}
										</p>
									</div>
								</Link>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item>
									<div className='text-center fw-semibold mb-1'>
										<Link to={ROUTES.PROFILE} className="text-decoration-none text-black">
											<i class="fa-solid fa-user me-2"></i>Profile
										</Link>
									</div>
								</Dropdown.Item>
								<Dropdown.Item>
									<div className='text-center fw-semibold'>
										{userToken ? (
											<Link
												onClick={() => localStorage.removeItem('fronxUserToken')}
												className="text-decoration-none text-black"
											>
												<i class="fa-solid fa-right-from-bracket me-2"></i>Logout
											</Link>
										) : (
											<Link to="/login" className="text-decoration-none text-black">Login</Link>
										)}
									</div>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>

				</nav>
			</div>
		</header>

	)
}

export default Header