import { useState } from "react";
import MainContext from "./MainContext";

export default function ContextState(props) {

  const [isToggled, setIsToggled] = useState(false);
  const [signInData, setSignInData] = useState();
  const [profile, setProfile] = useState();
  const [signupOTP, setSignupOTP] = useState();
  const [userSetting, setUserSetting] = useState({});

  const value = {
    isToggled, setIsToggled,
    signInData, setSignInData,
    profile, setProfile,
    signupOTP, setSignupOTP,
    userSetting, setUserSetting,
  }

  return (
    <MainContext.Provider value={value}>
      {props.children}
    </MainContext.Provider>
  )

}