import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { DeleteUserAPI } from '../../Configs/Utils/Apis/UserApis';

const UserActiveDactiveModal = ({ setDeleteUserModalStatus, userId, getAllUser }) => {

  console.log("userId", userId)

  const [loading, setLoading] = useState(false)
  const controllers = [];

  const handleDelete = async () => {
    setLoading(true)
    const controller = new AbortController()
    controllers.push(controller)

    // const body = {
    //   status: "inactive"
    // }

    const body = {
      status: userId?.status === "inactive" ? 'active' : 'inactive'
    }

    const response = await DeleteUserAPI(userId.id, body, controller)
    if (response.status === 200) {
      toast.success(response.data.message)
      setDeleteUserModalStatus(false)
      setLoading(false)
      getAllUser()
    } else {
      toast.error(response.response.data.message);
      setLoading(false)
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <h5>Confirmation Alert!</h5>
      </Modal.Header>
      <Modal.Body>
        {
          userId?.status === "inactive" ?
            <p>Are you sure to active this User ?</p> :
            <p>Are you sure to inactive this User ?</p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button className='mt-3 me-2' disabled={loading} variant="secondary" onClick={() => setDeleteUserModalStatus(false)}>No <i class="fa-solid fa-xmark"></i></Button>
        <Button className='mt-3 me-2' disabled={loading} variant="secondary" onClick={handleDelete}>Yes <span className='ms-1'><i class="fa-solid fa-check"></i></span></Button>
      </Modal.Footer>
    </>
  )
}

export default UserActiveDactiveModal
