import { apiHandler, apiHandlerWitFile, apiHandlerWithoutToken } from "../ApiHandler";
import { REQUEST_METHODS } from "../RequestMethods";

export const cancelApiRequest = (controllers) => {
  for (const controller of controllers) {
    controller.abort();
  }
}

export const LoginAPI = async (data, controller) => {
  try {
    const response = await apiHandlerWithoutToken(
      REQUEST_METHODS.POST,
      `api/common/login`,
      data,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const SignUpAPI = async (data, controller) => {
  try {
    const response = await apiHandlerWithoutToken(
      REQUEST_METHODS.POST,
      `api/common/signup`,
      data,
      controller
    );
    return response;
  } catch (error) {
    return error;
  }
}

export const EmailVerifyOTPAPI = async (data, controller) => {
  try {
    const response = await apiHandlerWithoutToken(
      REQUEST_METHODS.POST,
      `api/common/emailVerifyOtp`,
      data,
      controller
    );
    return response;
  } catch (error) {
    return error;
  }
}

export const ReSendOTPAPI = async (data, controller) => {
  try {
    const response = await apiHandlerWithoutToken(
      REQUEST_METHODS.POST,
      `api/common/resendOtp`,
      data,
      controller
    );
    return response;
  } catch (error) {
    return error;
  }
}

export const ForgotPasswordAPI = async (data, controller) => {
  try {
    const response = await apiHandlerWithoutToken(
      REQUEST_METHODS.POST,
      `api/common/forgotPassword`,
      data,
      controller
    );
    return response;
  } catch (error) {
    return error;
  }
}

export const RestorePasswordAPI = async (id, data, controller) => {
  try {
    const response = await apiHandlerWithoutToken(
      REQUEST_METHODS.PATCH,
      `api/common/reset-password/${id}`,
      data,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getProfileAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/common/getProfile`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getTransactionCalculationAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/transaction-calculation`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getDashboardTransactionAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/dashboard-transaction?startDate=2024-11-18&endDate=2024-11-18`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getDashboardTransactionSearchAPI = async (startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/dashboard-transaction?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getDepositTransactionAPI = async (startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/depositWallet?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getWithdrawalTransactionAPI = async (startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/withdrawalRequest?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getReferralTransactionAPI = async (startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/referralRewardWallet?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getReferralLinkSharableAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/isReferralLinkSharable`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getUserSettingAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/setting`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const updateProfileAPI = async (body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.POST,
      `api/common/updateProfile`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const withdrawalRequestAPI = async (body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.POST,
      `api/users/transaction/store/walletWithdrawalRequest/`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getAllUserAPI = async (currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/users?page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getAllUserSearchAPI = async (search, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/users?search=${search}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getOneUserAPI = async (id, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/users/getOne/${id}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getWithdrawalUserWiseAPI = async (id, startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/transaction/withdrawalRequest/${id}?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getDepositUserWiseAPI = async (id, startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/transaction/depositWallet/${id}?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const AddAmountAPI = async (id, body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.POST,
      `api/admin/transaction/store/addWallet/${id}`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const UpdateWithdrawalRequestStatusAPI = async (id, body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.PATCH,
      `api/admin/transaction/update/withdrawalRequest/${id}`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

// Admin API Start
export const AdminTransactionAPI = async (startDate, endDate, search, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/transaction?startDate=${startDate}&endDate=${endDate}&search=${search}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const AdminDepositTransactionAPI = async (startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/transaction/depositWallet?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const AdminWithdrawalTransactionAPI = async (startDate, endDate, status, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/transaction/withdrawalRequest?startDate=${startDate}&endDate=${endDate}&status=${status}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const AdminReferralDetailsAPI = async (id, startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/transaction/referralRewardWallet/${id}?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getSettingAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/setting`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const updateSettingAPI = async (body, controller) => {
  try {
    const response = await apiHandlerWitFile(
      REQUEST_METHODS.POST,
      `api/admin/setting/update`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const updatePasswordAPI = async (body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.POST,
      `api/common/change-password`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

// Wallet Address Status Change API start
export const getUserWiseWalletAddressAPI = async (id, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/walletRequest/getAllWalletAddressRequest/${id}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const ChangeWalletAddressRequestAPI = async (body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.POST,
      `api/users/walletRequest/updateWalletAddress`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const UpdateWalletAddressStatusChangeAPI = async (id, body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.PATCH,
      `api/admin/walletRequest/updateWalletAddressRequest/${id}`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const DeleteUserAPI = async (id, body, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.PATCH,
      `api/admin/users/update/status/${id}`,
      body,
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getStackingRewardAPI = async (startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/users/transaction/stackingReward?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const deleteDepositAPI = async (id, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.DELETE,
      `api/admin/transaction/delete/deposit/${id}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const getAdminStackingRewardAPI = async (id, startDate, endDate, currentPage, perPage, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/transaction/stackingReward/${id}?startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${perPage}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

// Notification API
export const GetAllNotificationAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.GET,
      `api/admin/notification/`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const ReadOneNotificationAPI = async (id, controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.PATCH,
      `api/admin/notification/read/${id}`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}

export const ReadAllNotificationAPI = async (controller) => {
  try {
    const response = await apiHandler(
      REQUEST_METHODS.PATCH,
      `api/admin/notification/readAll`,
      {},
      controller
    );
    return response
  } catch (error) {
    return error;
  }
}