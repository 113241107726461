import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({ totalPage, currentPage, setCurrentPage }) => {

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage + 1);
  }

  return (
    <>
      <ReactPaginate
        previousLabel={<i class="fa-solid fa-angles-left"></i>}
        nextLabel={<i class="fa-solid fa-angles-right"></i>}
        pageCount={Math.ceil(totalPage)}
        onPageChange={handlePageClick}
        forcePage={currentPage - 1}
        containerClassName='wellnessPagination'
        previousLinkClassName='paginationLink'
        nextLinkClassName='paginationLink'
        disabledClassName='paginationDisabled'
        activeClassName='paginationActive'
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </>
  )
}

export default Pagination