import React from 'react'

const Footer = () => {
  return (
    <footer class="page-footer">
			<p class="mb-0">Copyright ©  2024. All right reserved.</p>
		</footer>
  )
}

export default Footer;